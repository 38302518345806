export function getStatusType(
    status: string,
    t: (key: string) => string
  ): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("documentStatus.draft") };
      case "ERROR":
        return { type: "error", label: t("documentStatus.error") };
      case "IN_PROCESSING":
        return { type: "test", label: t("documentStatus.in_processing") };
      case "PROCESSED":
        return { type: "active", label: t("documentStatus.processed") };
      case "IN_ACCEPTANCE":
        return { type: "test", label: t("documentStatus.in_acceptance") };
      case "IN_CONFIRMATION":
        return { type: "test", label: t("documentStatus.in_confirmation") };
      case "WITHDRAWN":
        return { type: "error", label: t("documentStatus.withdrawn") };
      case "REJECTED":
        return { type: "error", label: t("documentStatus.rejected") };
      case "SIGNED_BY_RECIPIENT":
        return {
          type: "active",
          label: t("documentStatus.signed_by_recipient"),
        };
      default:
        return { type: "new", label: t("documentStatus.new") }; // Для пустого статуса или неизвестного статуса
    }
  }
  export function getStatusTypeForOrders(status: string,t: (key: string) => string): { type: string; label: string } {
    switch (status) {
      case "DRAFT":
        return { type: "test", label: t("orderList2.draft") };
      case "ERROR":
        return { type: "error", label: t("orderList2.error") };
      case "IN_PROCESSING":
        return { type: "test", label: t("orderList2.in_processing") };
      case "READY_TO_RECEIVE":
        return { type: "active", label: t("orderList2.ready_to_receive") };
      case "PROCESSED_NO_FUNDS":
        return { type: "test", label: t("orderList2.processed_no_funds") };
      case "PROCESSED_PAID":
        return { type: "active", label: t("orderList2.processed_paid") };
      default:
        return { type: "new", label: t("orderList2.unsaved") }; // Для пустого статуса или неизвестного статуса
    }
  }

  export function getDescription(value: any, t: (key: string) => string): string {
    switch (value) {
      case 1:
        return t("documentType.notification_from_third_countries");
      case 2:
        return t("documentType.withdrawal_notification");
      case 3:
        return t("documentType.re_introduction_notification");
      case 4:
        return t("documentType.notification_from_eaes");
      case 5:
        return t("documentType.goods_transfer_act");
      case 6:
        return t("submenu.personalization");
      default:
        return t("documentType.unknown");
    }
  }

  export function getMarkStatusType(status: string,t: (key: string) => string): { type: string; label: string } {
    switch (status) {
      case "ISSUED":
        return {
          type: "ISSUED",
          label: t("documents_file_upload.code_status_issued"),
        };
      case "INFLICTED":
        return {
          type: "INFLICTED",
          label: t("documents_file_upload.code_status_inflicted"),
        };
      case "IN_CIRCULATION":
        return {
          type: "IN_CIRCULATION",
          label: t("documents_file_upload.code_status_in_circulation"),
        };
      case "WITHDRAWN_FROM_CIRCULATION":
        return {
          type: "WITHDRAWN_FROM_CIRCULATION",
          label: t("documents_file_upload.code_status_withdrawn"),
        };
      default:
        return { type: "", label: "-" };
    }
  }