import ReactSelect, { GroupBase } from "react-select";
import StateManagedSelect from "react-select";
import cx from "classnames";
import style from "./SelectCustom.module.css";
import type {} from "react-select/base";

interface OptionType {
  label: string;
  value: any;
}

declare module "react-select/base" {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    size?: "sm" | "md" | "xl" | "dm"; // dm = Dynamic
    isError?: boolean;
    withoutStyle?: boolean;
  }
}

export const SelectCustom: StateManagedSelect = ({
  size = "dm",
  isError = false,
  withoutStyle = false,
  menuPlacement='bottom',
  ...props
}) => {
  return (
    <ReactSelect
      isClearable={true}
      menuPlacement={menuPlacement}
      components={{
        IndicatorsContainer: ({}) => (
          <div className={cx(style.indicatorsContainer)}>
            <img src="/icons/arrowDown.svg" />
          </div>
        )
      }}
      classNames={{
        container: () => {
          return cx(style[`size-${size}`], style.container);
        },
        control: ({ isFocused, isDisabled }) => {
          if (withoutStyle) {
            return cx(
              isFocused && style["border-focused"],
              isDisabled && style["border-disabled"],
              style[`size-${size}`],
              style.control,
              style.withoutStyle,
              isError ? style.errorStyle : "",
            );
          } else {
            return cx(
              isFocused && style["border-focused"],
              isDisabled && style["border-disabled"],
              style[`size-${size}`],
              style.control,
              style.border,
              isError ? style.errorStyle : "",
            );
          }
        },
        placeholder: () => {
          // return cx(style[`size-${size}`], style.placeholder);
          return cx(
      style[`size-${size}`],
      style.placeholder,
      isError ? style.placeholderred : "", 
    );
        },
        input: () => {
          return cx(style[`size-${size}`], style.input);
        },
        valueContainer: (state) => {
          return cx(
            style[`size-${size}`],
            style.valueContainer,
            state ? style.focused : "",
          );
        },
        indicatorSeparator: () => {
          return style.indicatorSeparator;
        },
        singleValue: () => {
          return cx(style.singleValue);
        },
        option: ({ isFocused, isSelected }) => {
          return cx(
            isFocused && style["border-focused"],
            isSelected && style["selected"],
            style[`size-${size}`],
            style.option,
          );
        },
        menu: () => {
          return cx(style.menu);
        },
        menuList: () => {
          return style.menuList;
        },
      }}
      {...props}
    />
  );
};
