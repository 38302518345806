import { PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { deleteMarks } from "../../../../common/api/documentsApi";
import AlertBox from "../../../../shared/Ui/AlertBox/AlertBox";
import { EmptyListPlaceholderContent } from "../../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../../shared/Ui/ModalComponent/ModalWindow";
import StatusBadge from "../../../../shared/Ui/StatusBadge/StatusBadge";
import Pagination from "../../../../shared/components/Table/Pagination";
import { useAppSelector } from "../../../../shared/hook";
import style from "./CheckResult.module.css";
import { deletePersonalization, deletePersonalizationMarks } from "../../../../common/api/nktApi";
import { getMarkStatusType } from "../../../../shared/documentUtils/dictionary";

export interface ICheckResult {
  isOpen: any;
  setIsOpen: any;
  problemMarks: any;
  docId: any;
  personalization?:any;
  onLoad?:any
}

export const CheckResult: React.FC<ICheckResult> = (props) => {
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const onModalClose = () => props.setIsOpen(false);


  //Тут генерация exel-док.
  const generateAndDownloadExcel = () => {
    // Пример данных для файла Excel

    // Создание нового workbook
    const workbook = XLSX.utils.book_new();
    const sheetName = "Sheet1";

    const customHeaders = [t("CheckResult.code"), t("CheckResult.id"), t("CheckResult.status"),t("CheckResult.markId"),t("CheckResult.reason")];

    // Преобразование данных в формат, понятный для xlsx
    const wsData = [
      // Object.keys(props.problemMarks[0]), // Заголовки столбцов
      customHeaders,
      ...props.problemMarks.map((obj: any) => Object.values(obj)), // Данные
    ];

    // Создание нового листа
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(workbook, ws, sheetName);

    // Сохранение файла
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.xlsx";
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };

  const handleDeleteMarks = async () => {
    if (props.personalization) {
      try {
        // Проходим по каждому изделию (product) в props.personalization
        for (const product of props.personalization) {
          // Собираем все ids маркировок для текущего изделия
          const markIds = product.marks.map((mark: any) => mark.id);
          
          // Удаляем все маркировки для текущего productId
          await deletePersonalizationMarks(props.docId, product.productId, markIds);
        }
    
        // Закрываем окно после успешного удаления
        props.setIsOpen(null);
    
      } catch (error) {
        setAlertOpen({
          status: "error",
          message: t("check_results.delete_error"),
        });
        console.error("Ошибка при удалении маркировок:", error);
      }
    } else {
      try {
      const marks = props.problemMarks.map((problem: any) => problem.id);
      const response = await deleteMarks(props.docId, marks).then(
        (response) => {
          props.setIsOpen(null);
        },
      );
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: t("check_results.delete_error"),
      });
      console.error("Ошибка при удалении маркировок:", error);
    }
    }

    if(props.onLoad){
      props.onLoad()
    }
  };

  const renderTable = () => {
    return (
      <div className="w-full">
        <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
          <div className="flex flex-col gap-[10px] w-full ">
            <div className="flex justify-between gap-[20px] items-center">
              <span>{t("documents_upload.checkResult")}</span>
              <button
                className="bg-[#e9f0f3] hover:brightness-90 text-[#678aa1] font-medium pb-[3px] px-[10px] rounded h-[40px]"
                type="button"
                onClick={generateAndDownloadExcel}
              >
                {t("documents_upload.donwloadResult")}
              </button>
            </div>
            {props.problemMarks?.marks?.length ?
            <span className="text-[14px]">{t("pagination.total")}:{props.problemMarks.marks.length}</span>
            :null}
          </div>
        </div>
        <div className="rounded-lg overflow-x-auto bg-white">
          <table className="max-w-full w-full leading-normal">
            <thead className="border-b-2 border-gray-300">
              <tr>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  №{t("documents_upload.serialNumber")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documents_file_upload.code")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documents_file_upload.code_status")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documents_upload.checkStatus")}
                </th>
              </tr>
            </thead>
            {!props.problemMarks ? (
              <tbody>
                <tr>
                  <td colSpan={8} className="text-center py-5">
                    <div>
                      <EmptyListPlaceholderContent
                        title={t("documents_upload.noData")}
                        description={''}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="font-normal text-center">
                {Array.isArray(props.problemMarks) &&
                  props.problemMarks
                    .slice(
                      pagination.pageIndex * pagination.pageSize,
                      (pagination.pageIndex + 1) * pagination.pageSize,
                    )
                    .map((value: any, index: any) => (
                      <tr key={`tr-${index}-${value.id}`}>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {index +
                            1 +
                            pagination.pageIndex * pagination.pageSize || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          {value.code || "-"}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          <div className="flex justify-center">
                            {getMarkStatusType(value?.status,t)?.label || "-"}
                          </div>
                        </td>
                        <td className="px-5 flex justify-center py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          <StatusBadge type="error">
                            {value?.reason || "-"}
                          </StatusBadge>
                        </td>
                      </tr>
                    ))}
              </tbody>
            )}
          </table>
        </div>
        <AlertBox isOpen={alertOpen} setIsOpen={setAlertOpen} />
        <Pagination
          totalItems={
            Array.isArray(props.problemMarks) ? props.problemMarks?.length : 0
          }
          totalPages={
            Array.isArray(props.problemMarks)
              ? Math.ceil(props.problemMarks?.length / pagination.pageSize)
              : 0
          }
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    );
  };

  return (
    <>
      <ModalComponent
        classNameModal={style.modalWrapper}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderTable()}
        isOpen={props.isOpen}
        onClose={onModalClose}
        footerContent={
          <button
            className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={() => handleDeleteMarks()}
          >
            {t("check_results.delete_all_and_continue")}
          </button>
        }
        cancelText={t("mainButtons.cancel")}
      />
    </>
  );
};

export default CheckResult;
