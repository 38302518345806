import { PaginationState } from "@tanstack/react-table";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { GetReferenceService } from "../../common/api/ordersApi";
import Calendar from "../../shared/Ui/Calendar/Calendar";
import CheckBox from "../../shared/Ui/CheckBox/CheckBox";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import SelectCustomFormik from "../../shared/Ui/SelectCustom/SelectCustomFormik";
import ActionDropwdownCell from "../../shared/components/Table/ActionDropwdownCell";
import Pagination from "../../shared/components/Table/Pagination";
import { ITable } from "../../shared/components/Table/types";
import { useAppDispatch, useAppSelector } from "../../shared/hook";
import style from "./ProductCard.module.css";
import MockSkeletonDocs from "./utils/MockSkeletonDocs";
import { getCardsList, GetjewelryGroup, getMetalsList, getPurityList } from "../../common/api/nktApi";

interface LooseObject {
  [key: string]: any;
}

export const ProductCard: React.FC<any> = () => {
  const { productTypeCode } = useParams();
  const [activeTooltipId, setActiveTooltipId] = useState<number | null>(null);
    const handleTooltipToggle = (id: number) => {
    setActiveTooltipId((prevId) => (prevId === id ? null : id));
    };
  const [sort, setSort] = useState<"ASC" | "DESC">("DESC");
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [deleteArray, setDeleteArray] = useState<any>([]);
  const [tableData, setTableData] = useState<ITable>({
    values: [{}, {}, {}],
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 3,
    totalPages: 1,
  });
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  type PaginationState = {
    pageIndex: number;
    pageSize: number;
  };
  
  const [pagination, setPagination] = useState<PaginationState>(() => {
    const savedPagination = localStorage.getItem('paginationCheques');
    return savedPagination ? JSON.parse(savedPagination) : { pageIndex: 0, pageSize: pageSize };
  });
  useEffect(() => {
    localStorage.setItem('paginationCheques', JSON.stringify(pagination));
  }, [pagination]);
  const navigate = useNavigate();

  useEffect(() => {
    onLoad();
  }, [pagination, location, sort]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [releaseMethods, setReleaseMethods] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [metalList , setMetalList] = useState<any>([])
  const [purityList,setPurityList] = useState<any>([])
  const [jewelryGroup,setJewelryGroup] = useState<any>([])
  const [countryOrigin,setCountryOrigin] = useState<any>([])
  const [initialValues, setInitialValues] = useState({
    orderName: "",
    ntin:'',
    metal:null,
    purity:null,
    countryCode:null,
    brand:'',
    article:''
  });
  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (inputValue?.length < 3 && metalList?.length === 0) {
      return t("documents_orders.no_data_title");
    } else {
      return t("documents_orders.no_data_title");
    }
  };
  console.log('jewelryGroup',jewelryGroup);
  
  const locationPath = location.pathname
      
      const nowLocation:any = locationPath.includes('my-cards') ? 'MY':'ALL' //тут пока затычка 
      console.log(nowLocation,'nowLocation');

  useEffect(() => {
    GetjewelryGroup()
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item.productTypeCode,
            label: item.name,
          }));
        setJewelryGroup(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });
    GetReferenceService("app/countries/list")
      .then((response: any) => {
        const options =
          response &&
          response?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        setCountryOrigin(options);
      })
      .catch((error: any) => {
        console.error("Error fetching bank catalog list:", error);
      });
    getMetalsList()
        .then((response: any) => {
          const optionsOgd = response?.map((e: any) => ({
            value: e.id,
            label: e.name,
          }));
  
          setMetalList(optionsOgd);
        })
        .catch((error) => {
          console.error("Error fetching bank catalog list:", error);
        });

    const clearSelected = (e: any) => {
      if (!(e.target.id === "filter-type")) {
        setIsFilterOpen(false);
      }
    };
    window.addEventListener("click", clearSelected);

    return () => window.removeEventListener("click", clearSelected);
  }, []);

  const calculateItemNumber = (index: any) => {
    return pagination.pageIndex * pagination.pageSize + index + 1;
  };
  const toggleSort = () => {
    const newSortDirection = sort === "ASC" ? "DESC" : "ASC";
    setSort(newSortDirection);
  };
  const selectedItem = jewelryGroup.find((item:any) => item.value == productTypeCode);

  async function onSearchOpen() {
    releaseMethods?.length === 0 &&
      (await GetReferenceService("app/release-methods/list")
        .then((response: any) => {
          const options =
            response &&
            response?.map((item: any) => ({
              value: item.id,
              label: item.name,
            }));
          setReleaseMethods(options);
        })
        .catch((error: any) => {
          console.error("Error fetching bank catalog list:", error);
        }));
  }
console.log(tableData,123);

  async function onLoad() { 
    setIsLoading(true);
    try {
      const response = await getCardsList(
        nowLocation,
        pagination.pageIndex + 1,
        pagination.pageSize,
        productTypeCode,
        initialValues.orderName,
        initialValues.purity,
        initialValues.ntin,
        initialValues.metal,
        initialValues.countryCode,
        initialValues.brand,
        initialValues.article,
      );

      setTableData({
        values: response.cards,
        currentPage: response.currentPage,
        itemsPerPage: response.itemsPerPage,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
      });
    } catch (error) {
      console.error("Error fetching documents list:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const renderActions = (value: any, navigate: any) => {
    const actions = [];
    actions.push({
      title: t("orderList.show"),
      action: () => {
        navigate(`/products-catalog/all-cards/${value.ntin}`)
      },
    });

    return actions;
  };

  const renderFilterInputs = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          setPagination((prevValues: PaginationState) => {
            return { ...prevValues, pageIndex: 0 };
          });
          setIsFilterOpen(false);
        }}
        // enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {(formik) => (
          <Form className=" w-[300px] p-[10px] ">
            <div className="flex flex-row align-center justify-between  ">
              <p className="my-3 text-lg font-semibold ">
                {t("documents_upload.filters")}
              </p>
              <button
                type="reset"
                onClick={() => {
                  setIsFilterOpen(false);
                }}
              >
                <img src="/icons/close.svg" />
              </button>
            </div>

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                  {t("AllCardsNtin.orderName")}
                </label>
              </div>
              <Field
                type="text"
                name="orderName"
                placeholder={t("documentLabels.choose_option")}
                className={style.placeholder}
                value={formik?.values?.orderName}
                onChange={(e: any) => {
                  formik.setFieldValue("orderName", e.target.value);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, orderName: e.target.value };
                  });
                }}
              />
            </div>

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                {t('AllCardsNtin.ntin')}
                </label>
              </div>
              <Field
                type="text"
                name="ntin"
                placeholder={t("documentLabels.choose_option")}
                className={style.placeholder}
                value={formik?.values?.ntin}
                onChange={(e: any) => {
                  formik.setFieldValue("ntin", e.target.value);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, ntin: e.target.value };
                  });
                }}
              />
            </div>

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                {t('AllCardsNtin.metal')}
                </label>
              </div>
              <Field
              noOptionsMessage={noOptionsMessage}
                value={formik?.values?.metal || null}
                component={SelectCustomFormik}
                name="metal"
                placeholder={t("documentLabels.choose")}
                isClearable={true}
     
                size="dm"
                options={metalList}
                onChange={(options: any) => {
                  formik.setFieldValue("metal", options);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, metal: options };
                  });
                  getPurityList(options)
                  .then((response: any) => {
                    const optionsOgd = response?.map((e: any) => ({
                      value: e.id,
                      label: e.code,
                    }));
            
                    setPurityList(optionsOgd);
                  })
                  .catch((error) => {
                    console.error("Error fetching bank catalog list:", error);
                  });
                }}
              />
            </div>

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                {t('AllCardsNtin.purity')}
                </label>
              </div>
              <Field
              noOptionsMessage={noOptionsMessage}
                value={formik?.values?.purity || null}
                component={SelectCustomFormik}
                name="purity"
                placeholder={t("documentLabels.choose")}
                isClearable={true}
                size="dm"
                options={purityList}
                onChange={(options: any) => {
                  formik.setFieldValue("purity", options);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, purity: options };
                  });
                }}
                isDisabled={formik?.values?.metal == null}
              />
            </div>

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                {t('AllCardsNtin.countryCode')}
                </label>
              </div>
              <Field
              noOptionsMessage={noOptionsMessage}
                value={formik?.values?.countryCode || null}
                component={SelectCustomFormik}
                name="countryCode"
                placeholder={t("documentLabels.choose")}
                isClearable={true}
                size="dm"
                options={countryOrigin}
                onChange={(options: any) => {
                  formik.setFieldValue("countryCode", options);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, countryCode: options };
                  });
                }}
              />
            </div>
            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                {t('AllCardsNtin.brand')}
                </label>
              </div>
              <Field
                type="text"
                name="brand"
                placeholder={t("documentLabels.choose_option")}
                className={style.placeholder}
                value={formik?.values?.brand}
                onChange={(e: any) => {
                  formik.setFieldValue("brand", e.target.value);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, brand: e.target.value };
                  });
                }}
              />
            </div>
            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                {t('AllCardsNtin.article')}
                </label>
              </div>
              <Field
                type="text"
                name="article"
                placeholder={t("documentLabels.choose_option")}
                className={style.placeholder}
                value={formik?.values?.article}
                onChange={(e: any) => {
                  formik.setFieldValue("article", e.target.value);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, article: e.target.value };
                  });
                }}
              />
            </div>

            <div className="flex flex-row gap-[10px]">
              <button
                type="submit"
                className=" bg-green text-white font-semibold py-2 px-4 rounded"
              >
                {t("mainButtons3.apply")}
              </button>
              <button
                type="reset"
                className=" bg-bg text-gray-500 font-semibold py-2 px-4 rounded"
                onClick={() => {
                  setStartDate(undefined);
                  setEndDate(undefined);
                  setInitialValues({
                    orderName:'',
                    ntin:'',
                    metal:null,
                    purity:null,
                    countryCode:null,
                    brand:'',
                      article:''

                  });
                  formik.resetForm({
                    values: {
                      orderName:'',
                      ntin:'',
                      metal:null,
                      purity:null,
                      countryCode:null,
                      brand:'',
                        article:''

                    },
                  });
                  setPagination((prevValues: PaginationState) => {
                    return { ...prevValues, pageIndex: 0 };
                  });
                  // setIsFilterOpen(false);
                }}
              >
                {t("mainButtons3.reset")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <div className="flex flex-1 flex-col min-w-0">
      <h2 className="my-3 text-xl font-semibold">{t('AllCardsNtin.productType')}: {selectedItem ? selectedItem.label : "Неизвестный тип изделия"}</h2>
      <div className="bg-white  my-5 p-5  pt-8 relative rounded-xl shadow-md flex flex-row align-center justify-between">
        <div></div>
        <div className="flex flex-row gap-[10px]">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onSearchOpen();
              setIsFilterOpen(true);
            }}
            className=" bg-green text-white font-semibold py-2 px-4 rounded"
            data-tooltip-offset={4}
            data-tooltip-id="filter-type"
          >
            {t("mainButtons3.filter")}
          </button>
          <button
            onClick={() => {
              setStartDate(undefined);
              setEndDate(undefined);
              setInitialValues({
                orderName:'',
                ntin:'',
                metal:null,
                purity:null,
                countryCode:null,
                brand:'',
                article:''
              });
              setPagination((prevValues: PaginationState) => {
                return { ...prevValues, pageIndex: 0 };
              });
            }}
          >
            <img src="/icons/update.svg" />
          </button>
        </div>
      </div>

      <div className="w-full">
        <div className="rounded-lg overflow-x-auto bg-white">
          <table className="max-w-full w-full leading-normal">
            <thead className="border-b-2 border-gray-300">
              <tr>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  №{t("documents_upload.serialNumber")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("AllCardsNtin.orderName")}
                </th>
                <th
                className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t('AllCardsNtin.ntin')}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t('AllCardsNtin.metal')}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t('AllCardsNtin.purity')}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t('AllCardsNtin.countryCode')}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t('AllCardsNtin.brand')}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t('AllCardsNtin.article')}
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <SkeletonTheme baseColor="#ebebeb" highlightColor="#c9c9c9">
                <tbody className="font-normal text-center">
                  {new Array(10).fill(1).map((_, index) => (
                    <MockSkeletonDocs key={index} index={index} />
                  ))}
                </tbody>
              </SkeletonTheme>
            ) : tableData?.values?.length === 0 || !tableData?.values ? (
              <tbody>
                <tr>
                  <td colSpan={8} className="text-center py-5">
                    <div>
                      <EmptyListPlaceholderContent
                        title={''}
                        description={t("documentLabels_3.no_matching_data")}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="font-normal text-center">
                {tableData?.values?.map((value, index) => (
                  <>
                    <tr
                        key={`tr-${index}-${value.id}`}
                        className="cursor-pointer hover:bg-gray-100"
                        onClick={() => {
                          navigate(`/products-catalog/all-cards/${value.ntin}`);
                        }}
                      >
                      {location.pathname === "/documents/drafts" && (
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                          <CheckBox
                            id={`checkBox${index}`}
                            checked={deleteArray.includes(value.id)}
                            onChange={() => {}}
                          />
                        </td>
                      )}

                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                        {calculateItemNumber(index) || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {value?.nameRu || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {value?.ntin || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                        {value?.metal || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                        {value?.purity || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                        {value?.countryName || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 text-center">
                        {value?.brand || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 text-center">
                        {value?.article || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600"
                      onClick={(e) => e.stopPropagation()}
                      >
                        <ActionDropwdownCell
                          key={`cell-${index}-${value.id}-actions`}
                          id={value.ntin}
                          actions={renderActions(value, navigate)}
                          activeTooltipId={activeTooltipId}
                          onTooltipToggle={handleTooltipToggle}
                        />
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <Pagination
          totalItems={tableData?.totalItems || 0}
          totalPages={tableData?.totalPages || 0}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
      <div className="z-[1000] relative	">
        <Tooltip
          isOpen={isFilterOpen}
          id="filter-type"
          className={classNames(style.wrapperTooltipFilter, "drop-shadow-2xl")}
          openOnClick={true}
          clickable={true}
          noArrow={true}
          opacity={1}
          place={"bottom-end"}
          afterShow={() => setIsFilterOpen(true)}
          afterHide={() => setIsFilterOpen(false)}
        >
          <div onClick={(e) => e.stopPropagation()}>{renderFilterInputs()}</div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ProductCard;
