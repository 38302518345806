import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DocumentsService from "../../common/api/documentsApi";
import { getOrdersList } from "../../common/api/ordersApi";

function MainScreen() {
  // @ts-ignore
  const DragDropContextAny: React.FC<any> = DragDropContext;
  // @ts-ignore
  const DroppableAny: React.FC<any> = Droppable;
  // @ts-ignore
  const DraggableAny: React.FC<any> = Draggable;
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([
    { id: 1, title: t("submenu.orders"), number: '0' },
    { id: 2, title: t("submenu.documents"), number: '0' },
  ]
  )
  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
  };



  function onLoad() {
    DocumentsService.getDocumentsList()
      .then((documentResponse) => {
        setItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.title === t("submenu.documents")) {
              return { ...item, number: documentResponse.totalItems.toString() };
            }
            return item;
          });
          return updatedItems;
        });
      });

    getOrdersList()
      .then((ordersResponse) => {
        setItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.title === t("submenu.orders")) {
              return { ...item, number: ordersResponse.totalItems.toString() };
            }
            return item;
          });
          return updatedItems;
        });
      });
  }


  useEffect(() => {
    setItems([
      { id: 1, title: t("submenu.orders"), number: '0' },
      { id: 2, title: t("submenu.documents"), number: '0' },
    ]);
    onLoad();
  }, [i18n.language]);

  return (
    <div className="w-full h-full">
      <div
        className="min-h-64 h-80 rounded-t-xl bg-cover bg-center flex items-end justify-end"
        style={{ backgroundImage: 'url("../../../assets/mainpage.webp")' }}
      >

      </div>

      <div className="w-auto h-auto bg-green rounded-b-xl p-5 relative shadow-md flex flex-col">
        <h1 className="pb-1 pr-1 pl-1 text-bg text-3xl font-semibold">
          {t("main.title")}
        </h1>
      </div>
      <DragDropContextAny onDragEnd={onDragEnd}>
        <DroppableAny direction="horizontal" droppableId="droppable">
          {(provided: any) => (
            <div
              className="w-full h-auto inline-flex py-5  flex-wrap"
              ref={provided.innerRef} {...provided.droppableProps} >
              {items.map((item, index) => (
                <DraggableAny key={item.id} draggableId={item.id.toString()} index={index}>
                  {(provided: any) => (
                    <div
                      className="bg-white max-w-[20%] min-w-[300px] w-full h-auto py-7 rounded-xl shadow-md flex p-5 items-center justify-between mr-[20px] mb-[20px] "
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="text-3xl font-semibold">
                        {item.title}
                      </div>
                      <div className=" text-3xl font-semibold bg-green rounded-xl py-2 px-4 text-white">
                        {item.number}
                      </div>
                    </div>
                  )}
                </DraggableAny>
              ))}
              {provided.placeholder}
            </div>
          )}
        </DroppableAny>
      </DragDropContextAny>
    </div>
  );
}

export default MainScreen;