import { getDocumentXMLForSigning, saveSignedDocumentXML } from "../../common/api/documentSignature";
// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";

export async function submitXmlProduct(
  id:any, navigate:any,
    setAlertOpen:any,
    setIsLoading:any,
    setErrorModal:any,
    setIsCheckOpen:any,
    t:any,
    navigateTo: any = 'inbox',
  ) {
    const ncalayerClient = new NCALayerClient();
    try {
      await ncalayerClient.connect();
    } catch (error) {
      setAlertOpen({
        status: "error",
        message: t("documentNotification.nca_no_connect"),
      });
      return;
    }

    let xml;
    try {
      setIsLoading(true);
      xml = await getDocumentXMLForSigning(id);
      console.log("xml", xml);
      if (!xml || !xml.data) {
        console.log("Не удалось получить XML документ для подписи.");
        return;
      }
      if (xml.data.errorFields && xml.data.errorFields?.length > 0) {
        setErrorModal(xml.data.errorFields);
        console.log("xml1", xml.data.errorFields);
        return;
      }
      if (xml.data.problemMarks && xml.data.problemMarks?.length > 0) {
        setIsCheckOpen(xml.data.problemMarks);
        console.log("xml2", xml.data.problemMarks);
        return;
      }
    } catch (error: any) {
      console.log("errorka", error);
      setErrorModal({
        singleError: true,
        errorMessage: error.response?.data?.message
          ? error.response?.data?.message
          : 
          t("documentNotification.unknown_error")
      });
      return;
    } finally {
      setIsLoading(false);
    }
    let xmlSignature;
    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml.data.xml,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST === "true"
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );
      console.log("Подпись XML:", xmlSignature[0]);
    } catch (error: any) {
      // setErrorModal(error.data.message);
      return;
    }

    try {
      setIsLoading(true);
      const documentIdValue = id ?? "0";
      await saveSignedDocumentXML(documentIdValue, xmlSignature[0]);
      alert(t("documentNotification.document_xml_success"));
      navigate(`/documents/${navigateTo}`);
    } catch (error) {
      const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
      console.log("e", e);
      const errorMessage = e.response?.data;
      alert(t("documentNotification.document_xml_error"));
      console.log("errorka", errorMessage);
      setErrorModal({
        singleError: true,
        errorMessage: errorMessage?.message
          ? errorMessage?.message
          : t("documentNotification.unknown_error"),
      });
    } finally {
      setIsLoading(false);
    }
  }