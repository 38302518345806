import { t } from "i18next";
import { AcceptRejectDocumentXML, postAcceptRejectXML } from "../../../../common/api/transferAcceptanceSignature";
// @ts-ignore
import { NCALayerClient } from "ncalayer-js-client";
import { getRevokeAct, postRevokeAct } from "../../../../common/api/revokeAct";



export async function AcceptXmlProduct(transferAcceptanceDocumentId:any,type: string,setErrorModal:any,navigate:any,setAlertOpen?:any,setIsCheckOpen?:any) {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
        if(setAlertOpen){
            setAlertOpen({
                status: "error",
                message: t("documentNotification.nca_no_connect"),
              });
        }
      return;
    }

    let xml;
    try {
      xml = await AcceptRejectDocumentXML(transferAcceptanceDocumentId, type);
      if (!xml || !xml.data) {
        console.log("Не удалось получить XML документ для подписи.");
        return;
      }
      if (xml.data.errorFields && xml.data.errorFields.length > 0) {
        setErrorModal(xml.data.errorFields);
        console.log("xml1", xml.data.errorFields);
        return;
      }
      if (xml.data.problemMarks && xml.data.problemMarks.length > 0) {
        if(setIsCheckOpen){
            setIsCheckOpen(xml.data.problemMarks);
        }
        console.log("xml2", xml.data.problemMarks);
        return;
      }
    } catch (error: any) {
      setErrorModal({
        singleError: true,
        errorMessage: error?.response?.data?.message
          ? error?.response?.data?.message
          : t("documentNotification.unknown_error"),
      });

      // setErrorModal(error.response.data.data);
      return;
    }
    let xmlSignature;
    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml.data.xml,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST === "true"
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );
      console.log("Подпись XML:", xmlSignature[0]);
    } catch (error: any) {
      setErrorModal(error.data.message);
      return;
    }

    try {
      const documentIdValue = transferAcceptanceDocumentId ?? "0";
      await postAcceptRejectXML(
        documentIdValue,
        xmlSignature[0],
        type === "ACCEPT" ? true : false,
      );
      alert(t("documentNotification.document_xml_success"));
      navigate("/documents/sent");
    } catch (error) {
      const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
      console.log("e", e);
      const errorMessage = e.response?.data;
      alert(t("documentNotification.document_xml_error"));
      console.log("errorka", errorMessage);
      setErrorModal({
        singleError: true,
        errorMessage: errorMessage?.message
          ? errorMessage?.message
          : t("documentNotification.unknown_error"),
      });
    }
  }

export async function RevokeAct(transferAcceptanceDocumentId:any,setErrorModal:any,navigate:any,setAlertOpen?:any,setIsCheckOpen?:any) {
    const ncalayerClient = new NCALayerClient();

    try {
      await ncalayerClient.connect();
    } catch (error) {
        if(setAlertOpen){
            setAlertOpen({
                status: "error",
                message: t("documentNotification.nca_no_connect"),
              });
        }
      return;
    }

    let xml;
    try {
      xml = await getRevokeAct(transferAcceptanceDocumentId);
      console.log("xml", xml);
      if (!xml || !xml.data) {
        console.log("Не удалось получить XML документ для подписи.");
        return;
      }
      if (xml.data.errorFields && xml.data.errorFields.length > 0) {
        setErrorModal(xml.data.errorFields);
        console.log("xml1", xml.data.errorFields);
        return;
      }
      if (xml.data.problemMarks && xml.data.problemMarks.length > 0) {
        if(setIsCheckOpen){
            setIsCheckOpen(xml.data.problemMarks);
        }
        console.log("xml2", xml.data.problemMarks);
        return;
      }
    } catch (error: any) {
      setErrorModal({
        singleError: true,
        errorMessage: error?.response?.data?.message
          ? error?.response?.data?.message
          : t("documentNotification.unknown_error"),
      });
      return;
    }
    let xmlSignature;
    try {
      xmlSignature = await ncalayerClient.basicsSignXML(
        NCALayerClient.basicsStoragesAll,
        xml.data.xml,
        NCALayerClient.basicsXMLParams,
        process.env.REACT_APP_ETRACK_TEST === "true"
          ? NCALayerClient.basicsSignerTestAny
          : NCALayerClient.basicsSignerSignAny,
        "ru",
      );
      console.log("Подпись XML:", xmlSignature[0]);
    } catch (error: any) {
      setErrorModal(error.data.message);
      return;
    }

    try {
      const documentIdValue = transferAcceptanceDocumentId ?? "0";
      await postRevokeAct(documentIdValue, xmlSignature[0]);
      alert(t("documentNotification_2.revoke_act_success"));
      navigate("/documents/sent");
    } catch (error) {
      const e = error as { response?: { data?: { message?: string } } }; // Утверждаем тип
      const errorMessage = e.response?.data;
      alert(t("documentNotification.document_xml_error"));

      setErrorModal({
        singleError: true,
        errorMessage: errorMessage?.message
          ? errorMessage?.message
          : t("documentNotification.unknown_error"),
      });
    }
  }