import { useEffect, useState, useCallback, useRef, ReactNode } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../hook";
import React, { Suspense } from "react";
import Cookies from 'js-cookie';
import { login, logout, updateIsRequest, updateOrganizationData } from "../slice/authSlice";
import { getRoleMatrix, refreshAccessToken, userInfo } from "../../common/api/authApi";
import useDebounce from "../../common/libs/useDebounce";
import Loader from '../Ui/Loader/Loader';
import { deleteRoleState, saveRoleState } from '../slice/roleMatrix';


interface IRedirectAuthorization {
  children: ReactNode;
}

export const RedirectAuthorization: React.FC<IRedirectAuthorization> = ({ children }) => {
  const [isLoginCheck, setIsLoginCheck] = useState<boolean>(true);
  // const [isRequesting, setIsRequesting] = useState(false);
  const userData = useAppSelector(state => state.auth.userData.iinBin);
  const roleMatrix = useAppSelector(state => state.roleMatrix);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams()
  const isInitialMount = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const refresh_token = Cookies.get("refresh_token");
  


  const clearCookies = () => {
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("token_expiry");
    Cookies.remove("SESSION", { path: "/", domain: ".edge-apps.com" });
    Cookies.remove("refresh_token", { path: "/", domain: ".edge-apps.com" });
  }

  useEffect(() => {
    if (!isLoading) return;
    setIsLoading(true); 

    const currentTime = Math.floor(Date.now() / 1000);
    const access_token = Cookies.get("access_token");
    const tokenExpiry = Cookies.get("token_expiry");
    const refresh_token = Cookies.get("refresh_token");

    if (!access_token && !refresh_token) {
      clearCookies();
      setIsLoginCheck(false);
      dispatch(deleteRoleState)
      dispatch(logout());
      navigate('/authorization');
      return;
    }

    const expiryTime = tokenExpiry ? parseInt(tokenExpiry, 10) : currentTime;
    if (currentTime >= expiryTime - 1200) {
      
      if (refresh_token) {
        refreshAccessToken(refresh_token)
          .then((res) => {
            Cookies.remove("refresh_token", { path: "/", domain: ".edge-apps.com" });
            Cookies.remove("token_expiry");

            Cookies.set("access_token", res.data.access_token);
            Cookies.set("refresh_token", res.data.refresh_token);
            Cookies.set("token_expiry", String(res.data.refresh_expires_in + currentTime));
            dispatch(login());
            setIsLoginCheck(true);
          })
          .catch((refreshError) => {
            clearCookies();
            setIsLoginCheck(false);
            dispatch(deleteRoleState)
            dispatch(logout());
            navigate('/authorization');
          });
      } else {
        clearCookies();
        setIsLoginCheck(false);
        dispatch(deleteRoleState)
        dispatch(logout());
        navigate('/authorization');
      }
    } else {
      dispatch(login());
      setIsLoginCheck(true);
    }
    
    if (!userData) {
      userInfo()
        .then(response => {
          dispatch(updateOrganizationData(response));
        })
        .catch(error => {
          dispatch(deleteRoleState);
          dispatch(logout());
          setIsLoginCheck(false);
          clearCookies();
          navigate('/authorization');
        });
    }

    if (!roleMatrix.isMatrix ) {
      getRoleMatrix()
        .then(response => {
          dispatch(saveRoleState(response.data));
        })
        .catch(error => {
          dispatch(deleteRoleState);
          dispatch(logout());
          setIsLoginCheck(false);
          clearCookies();
          navigate('/authorization');
        });
    } 

    setIsLoading(false);
  }, [navigate, location.pathname, param]);

  if (isLoading || !roleMatrix.isMatrix || !userData) return <Loader/>;


  if (!isLoginCheck || !refresh_token) {
    return <Navigate to="/authorization" />;
  }

  return <>{children}</>; 
};