import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import style from "./index.module.css"; // Импорт модуля стилей
import ModalComponent from "../ModalComponent/ModalWindow";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const param = useParams();
  const [isPoliticModalOpen, setPoliticModalOpen] = useState(false);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const modalType = urlParams.get("modal");

    if (modalType === "privacy-policy") {
      setPoliticModalOpen(true);
    } 
  }, [param, location]);

  return (
    <>
      <div className={style.footerBackground}>
        <div className={style.footerContainer}>
          <div className={style.footerContent}>
            <div className={style.footerLeft}>
              <p className={style.footerP}>E-track © 2024</p>
            </div>
            <div className={style.footerRight}>
              <div className={style.footerLink}>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default Footer;
