import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./index.module.css";
import QrScannerComponent from "../Qr/QrScannerComponent";
import ModalWindow from "../ModalComponent/ModalWindow";
import ModalButton from "../../../../shared/Ui/Buttons/ModalButtons";
import { GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { MarkSearch } from "../../../../common/api/nktApi";
import { normalizedLocale } from "../../../../common/libs/normalizedLocale";
import ModalComponent from "../../../../shared/Ui/ModalComponent/ModalWindow";
import Loader from "../../../../shared/Ui/Loader/Loader";
import StatusBadge from "../../../../shared/Ui/StatusBadge/StatusBadge";


const LandingBody = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t, i18n } = useTranslation();
  const [errorModal, setErrorModal] = useState<any>(null);
  const [isScannerOpen, setIsScannerOpen] = useState(false); // Состояние для управления видимостью сканера
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для управления видимостью модалки
  const [scanResult, setScanResult] = useState<string | null>(null); // Состояние для хранения результата сканирования
  const [token,setToken] = useState<any>()
  const [isRequestPending, setIsRequestPending] = useState(false); // Добавьте флаг для управления запросами
  const [isLoadin, setIsLoading] = useState(false);
  const locale = normalizedLocale(i18n.language);
  const [initialValues,setInitialValues] =useState({
    markCode: "002000001000496(ZsccGd53cf46f",
    weight: 65,
    size: 24,
    ntin: "00090898978089",
    status:'',
    nktCard: {
      nameRu: "dddddd",
      nameKz: "string",
      ntin: "00090898978089",
      metal: "string",
      purity: "string",
      gtin:"stringstringst",
      shortNameRu: "string",
      shortNameKz: "string",
      brand: "string",
      countryCode: "st",
      countryName: "САН-ТОМЕ И ПРИНСИПИ",
      article: "string",
      id: 136,
      createdAt: "29.08.2024 06:52:44",
      productCode: "00200000100049",
      kpved: [
        {
          kpvedCode: "stringst",
          kpvedLabel: "string",
        },
      ],
      tnved: [
        {
          tnvedCode: "8888888888",
          tnvedLabel: "string",
        },
      ],
      producerRu: "string",
      producerKz: "string",
      coatingRu: "string",
      coatingKz: "string",
      insertion: [
        {
          insertionMaterial: "string",
          insertionCount: 0,
          insertionWeight: "1",
        },
      ],
      image: [
        {
          imageUrl: "string",
        },
      ],
      applicant: "940408450442",
    },
  }) 

  useEffect(() => {
    const loadToken = async () => {
      
      if (!executeRecaptcha) {
        console.log("reCAPTCHA не загружена");
        return;
      }
      const token = await executeRecaptcha("action_name");
      setToken(token); 
      console.log("reCAPTCHA token:", token);
    };
  
    loadToken(); 
  }, []); 

  
  const handleScanButtonClick = () => {
    setIsScannerOpen(true); // Открываем сканер при нажатии на кнопку
  };

  const handleCloseScanner = () => {
    setIsScannerOpen(false); // Закрываем сканер
  };
  const handleScanResult = async (result: string) => {
    try {
      if (isRequestPending) return
      setIsRequestPending(true)
      if (result.length !== 29) {
        setIsScannerOpen(false)
        setErrorModal(t("qrScaner.simbolsError"));
        setIsRequestPending(false)
        return; 
      }

      setScanResult(result);
  
      if (!executeRecaptcha) {
        console.error("reCAPTCHA не загружена");
        return;
      }
  
      const token = await executeRecaptcha("action_name");
      if (!token) {
        console.error("Не удалось получить reCAPTCHA токен");
        return;
      }
setIsLoading(true)
      const response = await MarkSearch(result, token); 
      if (response) {
        console.log('response',response);
        setInitialValues(response);
        setIsModalOpen(true);
      } else {
      }
    } catch (error: any) {
      console.log(error,'error');
      setErrorModal(replaceFieldNames(error?.response.data.message));
    }
    setIsLoading(false)
    setIsRequestPending(false)
    console.log('initial',initialValues);
    setIsScannerOpen(false);
    
  };
  function getMarkStatusType(status: string): { type: string; label: string } {
    switch (status) {
      case "ISSUED":
        return {
          type: "ISSUED",
          label: t("documents_file_upload.code_status_issued"),
        };
      case "INFLICTED":
        return {
          type: "INFLICTED",
          label: t("documents_file_upload.code_status_inflicted"),
        };
      case "IN_CIRCULATION":
        return {
          type: "IN_CIRCULATION",
          label: t("documents_file_upload.code_status_in_circulation"),
        };
      case "WITHDRAWN_FROM_CIRCULATION":
        return {
          type: "WITHDRAWN_FROM_CIRCULATION",
          label: t("documents_file_upload.code_status_withdrawn"),
        };
        case "PRE_SALE":
        return {
          type: "PRE_SALE",
          label: t("NewCheque.PRE_SALE"),
        };
      default:
        return { type: "", label: "-" };
    }
  }
  interface StatusType {
    type: string;
    label: string;
  }
  const statusTypes: { [key: string]: StatusType } = {
    PRE_SALE: { type: "test", label: t("NewCheque.PRE_SALE") },
    DRAFT: { type: "test", label: t("orderList2.draft") },
    ERROR: { type: "error", label: t("orderList2.error") },
    IN_PROCESSING: { type: "test", label: t("orderList2.in_processing") },
    READY_TO_RECEIVE: {
      type: "active",
      label: t("orderList2.ready_to_receive"),
    },
    PROCESSED_NO_FUNDS: {
      type: "error",
      label: t("orderList2.processed_no_funds"),
    },
    PROCESSED_PAID: { type: "active", label: t("orderList2.processed_paid") },
    UNSAVED: { type: "", label: t("orderList2.unsaved") },
    WITHDRAWN_FROM_CIRCULATION: { type: "test", label: t("orderList2.unsaved") },
  };
  
  const replaceFieldNames = (message: string): string => {
    const fieldNamesMap: { [key: string]: string } = {
      "ISSUED": t("documents_file_upload.code_status_issued"),
      "INFLICTED": t("documents_file_upload.code_status_inflicted"),
      "IN_CIRCULATION": t("documents_file_upload.code_status_in_circulation"),
      "WITHDRAWN_FROM_CIRCULATION": t("documents_file_upload.code_status_withdrawn"),
      "PRE_SALE": t("NewCheque.PRE_SALE"),
    };
    for (const englishName in fieldNamesMap) {
      if (message.includes(englishName)) {
        message = message.replace(englishName, fieldNamesMap[englishName]);
      }
    }
  
    return message;
  };




  
  if (isLoadin) return <Loader />;
  
  
  

  
  
  const body = () => {
    return (
      <>
        <div className="w-full flex flex-col bg-white p-[30px] px-[30px] gap-[10px] border-b border-gray-200 ">
          <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
          <img src="/assets/docs.png" className="w-[20px]" />
            <div className="uppercase">{t("productCard.title")}</div>
          </div>
          <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.fullName")}
              <p className="font-light">
              {locale.includes('ru') ? `${initialValues?.nktCard?.nameRu ||'-'}` : `${initialValues?.nktCard?.nameKz ||'-'}`}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.publicationDateTime")}
            <p className="font-light">
            {initialValues?.nktCard?.createdAt || '-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.shortName")}
            <p className="font-light">
            {locale.includes('ru') ? `${initialValues?.nktCard?.shortNameRu ||'-'}` : `${initialValues?.nktCard?.shortNameKz ||'-'}`}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.creatorIIN")}
            <p className="font-light">
            {initialValues?.nktCard?.applicant ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("CheckResult.code")}
            <p className="font-light">
            {initialValues?.markCode ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("qrScaner.markStatus")}
            <StatusBadge
                                  type={statusTypes[initialValues.status]?.type || "unsaved"}
                                  fontSize="15px"
                                  circleSize="8px"
                                >
                                  {getMarkStatusType(initialValues?.status)?.label ||
                                    t("documents_upload.unknownStatus")}
                                </StatusBadge>
            </div>
          </div>
        </div>
        
        <div className="w-full flex flex-col bg-white  p-[30px] gap-[30px] max-w-[1200px] border-b border-gray-200">
          <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px] ">
          <img src={"/assets/docs.png"} className="w-[20px]" alt="icon" />
            <div className="uppercase">{t("productCard.productDescription")}</div>
          </div>
          <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.productType")}
              <p className="text-gray-600 font-light">
              {initialValues?.nktCard?.productCode ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.ntin")}
            <p className="text-gray-600 font-light">
            {initialValues?.nktCard?.ntin ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.tnvedCode")}
              <p className="text-gray-600 font-light">
              {initialValues?.nktCard?.tnved[0]?.tnvedCode ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.tnvedDescription")}
              <p className="font-light">
              {initialValues?.nktCard?.tnved[0]?.tnvedLabel ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.kpvedCode")}
            <p className="text-gray-600 font-light">
            {initialValues?.nktCard?.kpved[0]?.kpvedCode ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.kpvedDescription")}
            <p className="text-gray-600 font-light">
            {initialValues?.nktCard?.kpved[0]?.kpvedLabel ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">    
GTIN
            <p className="text-gray-600 font-light">
            {initialValues?.nktCard?.gtin ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.countryOfOrigin")}
              <p className="font-light">
              {initialValues?.nktCard?.countryName ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.manufacturerNameRU")}
              <p className="font-light">
              {locale.includes('ru') ? `${initialValues?.nktCard?.producerRu ||'-'}` : `${initialValues?.nktCard?.producerKz ||'-'}`}
              </p>
            </div>
          </div>
        </div>
  
        <div className="w-full flex flex-col bg-white  p-[30px] gap-[30px] max-w-[1200px]  border-b border-gray-200">
          <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
            <img src="assets/docs.png" className="w-[20px]" />
            <div className="uppercase">{t("productCard.productCharacteristics")}</div>
          </div>
          <div className="flex gap-x-[100px] gap-y-[20px] text-base flex-wrap">
            <div className="text-gray-600 w-[300px] font-semibold">
              <div className={style.tooltipWrapper}>
              {t("productCard.mainMaterial")}
              </div>
              <p className="text-gray-600 font-light">
              {initialValues?.nktCard?.metal ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.sampleCompliance")}
              <p className="font-light">
              {initialValues?.nktCard?.purity ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.coatingRU")}
              <p className="font-light">
              {locale.includes('ru') ? `${initialValues?.nktCard?.coatingRu ||'-'}` : `${initialValues?.nktCard?.coatingKz ||'-'}`}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productCard.article")}
              <p className="font-light">
              {initialValues?.nktCard?.article ||'-'}
              </p>
            </div>
             <div className="text-gray-600 w-[300px] font-semibold">
             {t("productCard.trademark")}
              <p className="font-light">
              {initialValues?.nktCard?.brand ||'-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
            {t("productPersonalization.weight")}
              <p className="font-light">
              {initialValues?.weight || '-'}
              </p>
            </div>
            <div className="text-gray-600 w-[300px] font-semibold">
             {t("productPersonalization.size")}
              <p className="font-light">
              {initialValues?.size || '-'}
              </p>
            </div>
            
          </div>
        </div>
  
        <div className="w-full flex flex-col bg-white rounded-3xl p-[30px] items-stretch gap-[30px] max-w-[1200px]">
  <div className="flex items-center text-base text-gray-600 font-light gap-x-[10px]">
    <img src="/assets/docs.png" className="w-[20px]" />
    <div className="uppercase">{t("productCard.inserts")}</div>
  </div>
  {initialValues?.nktCard?.insertion?.map((insert, index) => (
    <React.Fragment key={index}>
      <div className="flex gap-x-[100px] gap-y-[20px] items-center text-base flex-wrap">
        <div className="text-gray-600 w-[300px] font-semibold">
          {t("productCard.insertMaterial")}
          <p className="text-gray-600 font-light">{insert?.insertionMaterial}</p>
        </div>
        <div className="text-gray-600 w-[300px] font-semibold">
          {t("productCard.insertCount")}
          <p className="text-gray-600 font-light">{insert?.insertionCount}</p>
        </div>
        <div className="text-gray-600 w-[300px] font-semibold">
          {t("productCard.weight")}
          <p className="text-gray-600 font-light">{insert?.insertionWeight}</p>
        </div>
      </div>
      {index < initialValues?.nktCard?.insertion.length - 1 && (
        <hr className="border-t border-gray-200 my-4 w-full" />
      )}
    </React.Fragment>
  ))}
</div>
        <div className="flex justify-end">
        <ModalButton
                type="button"
                onClick={()=>setIsModalOpen(false)}
                color={'green'}
              >
                {t("mainButtons.close")}
              </ModalButton>
        <div className="pb-[2px]"></div>
      </div>
     
      </>
    );
  };
  

  return (<>
<ModalComponent
  isOpen={errorModal }
  onClose={() => setErrorModal(null)}
  headerContent={
    <h1 className="h__modal">
      {t("documentNotification.notification")}
    </h1>
  }
  content={
    <div className="error-list">
      <ul>
        {Array.isArray(errorModal) && errorModal.length > 0 ? (
          errorModal.map((error: any, index: any) => (
            <li className="border-b border-gray-300 py-1" key={index}>
              {t(`error_dictionary.${error}`)}
            </li>
          ))
        ) : typeof errorModal === "string" ? (
          <li>{errorModal}</li> 
        ) : (
          <li>{t("documentNotification.no_errors")}</li>
        )}
      </ul>
    </div>
  }
  customContent={
    <ModalButton
      type="button"
      onClick={() => {
        setErrorModal(null);
      }}
    >
      {t("mainButtons.accept")}
    </ModalButton>
  }
/>


  
    <div className={style.container}>
      <div className={style.middleContainer}>
        <button className={style.middleButton} onClick={handleScanButtonClick}>
          <img
            src={"/icons/datamatrix.svg"}
            alt="E-Track Logo"
            className={`h-6 pl-2 origin-left font-medium text-xl duration-200 brightness-0 invert`}
          />
          {t("qrScaner.mainButton")}
        </button>
        {/* <button
      className="m-auto"
      onClick={async () => {
        if (executeRecaptcha) {
          const token = await executeRecaptcha("action_name");
          console.log("token:", token);
        } else {
          console.warn("executeRecaptcha is undefined");
        }
      }}
    >
      Получить токен
    </button> */}
      </div>
      
      {/* Отображаем компонент сканера, если он открыт */}
      {isScannerOpen && (
        <div className={style.scannerOverlay}>
          <QrScannerComponent onClose={handleCloseScanner} onScanResult={handleScanResult} />
        </div>
      )}

      {/* Модалка для отображения результата сканирования */}
    <ModalWindow isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)} headerContent={t("Результат сканирования")} 
    content={body()}
    />
    </div>
     </>
  );
 
};

export default LandingBody;
