import React, { useState } from "react";
import { FaAngleUp, FaRegBell } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import { ChangingLanguage } from "../ChangingLanguage";
import { useAppSelector } from "../../hook";
import { useTranslation } from "react-i18next";
import { normalizedLocale } from "../../../common/libs/normalizedLocale";

function Header() {
  const [open, setOpen] = useState(false);
  const userData: any = useAppSelector((state) => state.auth.userData);
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);

  return (
    <header className="flex flex-row items-center grow-0 flex-none h-16 p-5 w-full justify-end">
      <div className="flex flex-col px-3">
        <span className="text-gray-600 font-semibold">
          {userData.userName
            ? `${userData.userName} ${userData.userSurname}`
            : "Тест Тестович"}
        </span>

        <span className="text-green py-1">
          {locale == "ru" ? `${userData.userPositionRu}` : `${userData.userPositionKz}`}
          {/* {userData.userPosition ? `${userData.userPositionRu}` : "-"} */}
        </span>
      </div>
      <div>
        <button
          onClick={() => setOpen(!open)}
          className="flex flex-row items-center"
          data-tooltip-id="user-profile"
        >
          <div className="relative flex justify-center items-center align-middle mx-auto object-cover rounded-full h-12 w-12 bg-white text-gray-400">
            <span className="text-gray-600">
              {" "}
              {userData.userName ? `${userData.userName[0]}` : "Т"}
            </span>
          </div>
          <span
            className={`cursor-pointer px-2  text-gray-600 ${!open && "rotate-180"}`}
          >
            <FaAngleUp />
          </span>
        </button>
        <Tooltip
          id="user-profile"
          className="bg-white rounded duration-200 mt-2"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            color: "#222",
            margin: "0",
            padding: "0",
            zIndex: "100",
          }}
          openOnClick={true}
          clickable={true}
          noArrow={true}
          opacity={1}
          place={"bottom-end"}
          afterShow={() => setOpen(true)}
          afterHide={() => setOpen(false)}
        >
          <ul id={`dropdown-example-user-profile`}>
            
              <li
                className="flex rounded-md p-2 cursor-pointer hover:bg-light-white items-center gap-x-4
              hover:bg-green hover:text-white"
              >
                <a className="origin-left duration-200"
                href="/profile"
                >
                  {t("submenu.profile")}
                </a>
              </li>
            
          </ul>
        </Tooltip>
      </div>
      <img
        src="/icons/bell.svg"
        className="mx-2 duration-200 group-hover:brightness-200 group-hover:invert-1"
      />
      <div className="bg-white p-2 ml-2 rounded ">
        <ChangingLanguage onlyDesktop={true} />
      </div>
    </header>
  );
}

Header.propTypes = {};

export default Header;
