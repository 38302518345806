import { format } from "date-fns";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  GetReferenceService,
  deleteOrder,
  downloadOrderFile,
  getOrdersList,
} from "../../common/api/ordersApi";
import ModalButton from "../../shared/Ui/Buttons/ModalButtons";
import Calendar from "../../shared/Ui/Calendar/Calendar";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../shared/Ui/ModalComponent/ModalWindow";
import SelectCustomFormik from "../../shared/Ui/SelectCustom/SelectCustomFormik";
import StatusBadge from "../../shared/Ui/StatusBadge/StatusBadge";
import ActionDropwdownCell from "../../shared/components/Table/ActionDropwdownCell";
import Pagination from "../../shared/components/Table/Pagination";
import { ITable } from "../../shared/components/Table/types";
import style from "./index.module.css";

import { PaginationState } from "@tanstack/react-table";
import classNames from "classnames";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Tooltip } from "react-tooltip";
import { useAppSelector } from "../../shared/hook";
import MockSkeleton from "./utils/MockSkeleton";
import PageNotRole from "../../shared/Ui/PageNotFound/PageNotRole";
import Loader from "../../shared/Ui/Loader/Loader";

interface StatusType {
  type: string;
  label: string;
}

function OrderList() {
  const { t, i18n } = useTranslation();
  const [acceptModal, setAcceptModal] = useState<any>(null);
  const [tableData, setTableData] = useState<ITable>();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const userData: any = useAppSelector((state) => state.auth.userData);
  const [pagination, setPagination] = useState(() => {
    const savedPagination = localStorage.getItem('paginationOrders');
    return savedPagination ? JSON.parse(savedPagination) : { pageIndex: 0, pageSize: pageSize };
  });
  useEffect(() => {
    localStorage.setItem('paginationOrders', JSON.stringify(pagination));
  }, [pagination]);
  const [activeTooltipId, setActiveTooltipId] = useState<number | null>(null);
  const handleTooltipToggle = (id: number) => {
    setActiveTooltipId((prevId) => (prevId === id ? null : id));
  };
  const [sort, setSort] = useState<"ASC" | "DESC">("DESC");
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [releaseMethods, setReleaseMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dowloadModal, setDowloadModal] = useState<any>(null);
  const roleMatrix = useAppSelector((state) => state.roleMatrix);
  const [role, setRole] = useState<any>(null);
  const [initialValues, setInitialValues] = useState({
    orderNumber: "",
    orderStatus: [],
    releaseMethod: null,
  });
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const statusTypes: { [key: string]: StatusType } = {
    DRAFT: { type: "test", label: t("orderList2.draft") },
    ERROR: { type: "error", label: t("orderList2.error") },
    IN_PROCESSING: { type: "test", label: t("orderList2.in_processing") },
    READY_TO_RECEIVE: {
      type: "active",
      label: t("orderList2.ready_to_receive"),
    },
    PROCESSED_NO_FUNDS: {
      type: "error",
      label: t("orderList2.processed_no_funds"),
    },
    PROCESSED_PAID: { type: "active", label: t("orderList2.processed_paid") },
    UNSAVED: { type: "", label: t("orderList2.unsaved") },
  };

  const calculateItemNumber = (index: any) => {
    return pagination.pageIndex * pagination.pageSize + index + 1;
  };

  useEffect(() => {
    const roleObj = () => { return roleMatrix?.roleMatrix?.find(item => item.id === 2) }

    setRole(roleObj)

    onLoad();
  }, [pagination, sort]);

  const handleDownloadClick = async (orderId: any, fileType: any) => {
    try {
      setIsLoading(true);
      const fileBlob = await downloadOrderFile(orderId, fileType);
      const downloadUrl = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `order-${orderId}.${fileType}`);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error("Ошибка при скачивании файла:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const clearSelected = (e: any) => {
      if (!e.target.classList.contains("react-tooltip")) {
        setIsFilterOpen(false);
      }
    };
    window.addEventListener("click", clearSelected);

    return () => window.removeEventListener("click", clearSelected);
  }, []);


  async function onLoad() {
    let docsForOperator = userData.selectRoleV2[0].roleId == 5
    console.log('docsForOperator',docsForOperator);
    
    const orderStatuses = initialValues?.orderStatus.length
  ? initialValues.orderStatus
  : docsForOperator 
    ? [
        "ERROR",
        "IN_PROCESSING",
        "READY_TO_RECEIVE",
        "PROCESSED_NO_FUNDS",
        "PROCESSED_PAID"
      ]
    : undefined;

    setIsLoading(true);
    try {
      const response = await getOrdersList(
        pagination.pageIndex + 1,
        pagination.pageSize,
        sort,
        startDate && format(startDate, "dd.MM.yyyy"),
        endDate
          ? format(endDate, "dd.MM.yyyy")
          : startDate
            ? format(startDate, "dd.MM.yyyy")
            : undefined,
        initialValues?.orderNumber && initialValues?.orderNumber,
        orderStatuses,
        initialValues?.releaseMethod || undefined,
      );
      setTableData({
        values: response?.orders,
        currentPage: response?.currentPage,
        itemsPerPage: response?.itemsPerPage,
        totalItems: response?.totalItems,
        totalPages: response?.totalPages,
      });
    } catch (error) {
      console.error("Error fetching user info:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const toggleSort = () => {
    const newSortDirection = sort === "ASC" ? "DESC" : "ASC";
    setSort(newSortDirection);
  };

  async function onSearchOpen() {
    releaseMethods?.length === 0 &&
      (await GetReferenceService("app/release-methods/list")
        .then((response: any) => {
          const options =
            response &&
            response?.map((item: any) => ({
              value: item.id,
              label: item.name,
            }));
          setReleaseMethods(options);
        })
        .catch((error: any) => {
          console.error("Error fetching bank catalog list:", error);
        }));
  }


  if (!role && isLoading) return <Loader />;

  if (!role || !role?.view) {
    return (
      <PageNotRole />
    )
  }

  const renderFilterInputs = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          setPagination((prevValues: PaginationState) => {
            return { ...prevValues, pageIndex: 0 };
          })
          setIsFilterOpen(false);
        }}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {(formik) => (
          <Form className="max-w-[1585px] w-full m-t-[20px]">
            <div className="flex flex-row align-center justify-between">
              <p className="my-3 text-lg font-semibold">
                {t("documents_upload.filters")}
              </p>
              <button
                type="reset"
                onClick={() => {
                  setIsFilterOpen(false);
                }}
              >
                <img src="/icons/close.svg" />
              </button>
            </div>

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                  {t("documents_upload.orderNumber")}
                </label>
              </div>
              <Field
                type="text"
                name="orderNumber"
                placeholder={t("documentLabels.choose_option")}
                className={style.placeholder}
                value={formik?.values?.orderNumber}
                onChange={(e: any) => {
                  formik.setFieldValue("orderNumber", e.target.value);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, orderNumber: e.target.value };
                  });
                }}
              />
            </div>

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                  {t("documentLabels.status")}
                </label>
              </div>
              <Field
                value={formik?.values?.orderStatus || null}
                component={SelectCustomFormik}
                name="orderStatus"
                placeholder={t("documentLabels.choose")}
                isClearable={true}
                isMulti={true}
                size="dm"
                options={[
                  ...(userData.selectRoleV2[0].roleId !== 5
                    ? [{ value: "DRAFT", label: t("orderList2.draft") }] 
                    : []),
                
                  { value: "ERROR", label: t("orderList2.error") },
                  {
                    value: "IN_PROCESSING",
                    label: t("orderList2.in_processing"),
                  },
                  {
                    value: "READY_TO_RECEIVE",
                    label: t("orderList2.ready_to_receive"),
                  },
                  {
                    value: "PROCESSED_NO_FUNDS",
                    label: t("orderList2.processed_no_funds"),
                  },
                  {
                    value: "PROCESSED_PAID",
                    label: t("orderList2.processed_paid"),
                  },
                ]}
                onChange={(options: any) => {
                  formik.setFieldValue("orderStatus", options);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, orderStatus: options };
                  });
                }}
              />
            </div>

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                  {t("orderList.releaseMethod")}
                </label>
              </div>
              <Field
                value={formik?.values?.releaseMethod}
                component={SelectCustomFormik}
                name="releaseMethod"
                placeholder={t("documentLabels.choose")}
                size="sm"
                isClearable={true}
                isMulti={false}
                options={releaseMethods}
                onChange={(option: any) => {
                  formik.setFieldValue("releaseMethod", option);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, releaseMethod: option };
                  });
                }}
              />
            </div>
            <Calendar
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />

            <div className="flex flex-row gap-[10px]">
              <button
                type="submit"
                className=" bg-green text-white font-semibold py-2 px-4 rounded"
              >
                {t("mainButtons3.apply")}
              </button>
              <button
                type="reset"
                className=" bg-bg text-gray-500 font-semibold py-2 px-4 rounded"
                onClick={() => {
                  setStartDate(undefined);
                  setEndDate(undefined);
                  setInitialValues({
                    orderNumber: "",
                    orderStatus: [],
                    releaseMethod: null,
                  });
                  formik.resetForm({
                    values: {
                      orderNumber: "",
                      orderStatus: [],
                      releaseMethod: null,
                    },
                  });
                  setPagination((prevValues: PaginationState) => {
                    return { ...prevValues, pageIndex: 0 };
                  });
                }}
              >
                {t("mainButtons3.reset")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <ModalComponent
        isOpen={dowloadModal}
        onClose={() => {
          setDowloadModal(null);
        }}
        headerContent={t("orderList2.downloadFile")}
        content={
          <div className="flex flex-col space-y-2">
            <button
              onClick={() => handleDownloadClick(dowloadModal, "PDF")}
              className="bg-red-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              type="button"
            >
              {t("orderList.download")} PDF
            </button>
            <button
              onClick={() => handleDownloadClick(dowloadModal, "CSV")}
              className="bg-green text-white font-semibold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              type="button"
            >
              {t("orderList.download")} CSV
            </button>
            <button
              onClick={() => handleDownloadClick(dowloadModal, "XLSX")}
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-purple-700 transition duration-300"
              type="button"
            >
              {t("orderList.download")} XLSX
            </button>
          </div>
        }
        customContent={
          <ModalButton
            type="button"
            color="grey"
            onClick={() => {
              setDowloadModal(null);
            }}
          >
            {t("mainButtons.close")}
          </ModalButton>
        }
      />
      <ModalComponent
        isOpen={acceptModal}
        onClose={() => {
          setAcceptModal(null);
        }}
        headerContent={
          <h1 className="h__modal">{t("orderList.notification")}</h1>
        }
        content={
          <p className="p__modal">
            {t("orderList.orderDelete")} №{acceptModal?.number}?
          </p>
        }
        customContent={
          <div className="flex flex-row gap-[10px]">
            <ModalButton
              type="button"
              onClick={() => {
                setAcceptModal(null);
              }}
            >
              {t("orderList.cancel")}
            </ModalButton>
            <ModalButton
              color="green"
              type="button"
              onClick={async () => {
                try {
                  await deleteOrder(acceptModal.id);
                  setAcceptModal(null);
                  onLoad();
                } catch (error) {
                  console.error("Ошибка при выполнении операции:", error);
                }
              }}
            >
              {t("orderList.delete")}
            </ModalButton>
          </div>
        }
      />
      <div className="flex flex-1 flex-col min-w-0">
        <h2 className="my-3 text-xl font-semibold">{t("orderList.title")}</h2>
        <div className="bg-white my-5 p-5 pt-8 relative rounded-xl shadow-md flex flex-row items-center justify-between">
          <div className="flex-grow">
            {roleMatrix.roleMatrix.find(item => item.id === 2)?.add && (
              <Link to={"new"}>
                <button
                  onClick={() => { }}
                  className="bg-green text-white font-semibold py-2 px-4 rounded"
                >
                  {t("orderList.create")}
                </button>
              </Link>
            )}
          </div>
          <div className="flex flex-row gap-[10px]">
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onSearchOpen();
                setIsFilterOpen(true);
              }}
              className="bg-green text-white font-semibold py-2 px-4 rounded"
              data-tooltip-offset={4}
              data-tooltip-id="filter-type"
            >
              {t("mainButtons3.filter")}
            </button>
            <button
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
                setInitialValues({
                  orderNumber: "",
                  orderStatus: [],
                  releaseMethod: null,
                });
                setPagination((prevValues: PaginationState) => {
                  return { ...prevValues, pageIndex: 0 };
                });
              }}
            >
              <img src="/icons/update.svg" alt="update" />
            </button>
          </div>
        </div>
        <div className="w-full">
          <div className={`rounded-lg overflow-x-auto bg-white ${style.custom_scrollbar}`}>
            <table className="max-w-full w-full leading-normal">
              <thead className="border-b-2 border-gray-300">
                <tr>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    №{t("documents_orders.number")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("orderList.orderId")}
                  </th>
                  <th
                    className="flex flex-row items-center justify-center px-5 py-3  text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("orderList.createdAt")}{" "}
                    <div className="flex flex-col">
                      <button className="h-6 w-6" onClick={toggleSort}>
                        <img
                          src={`/icons/arrow${sort === "ASC" ? "Down2" : "-top"}.svg`}
                          className={`block h-6 w-6  ${sort === "ASC" ? "text-red-500" : ""}`}
                          alt={sort === "ASC" ? "Ascending" : "Descending"}
                        />
                      </button>
                    </div>
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200 "
                    scope="col"
                  >
                    {t("orderList.status")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("orderList.releaseMethod")}
                  </th>
                  <th
                    className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                    scope="col"
                  >
                    {t("orderList.amount")}
                  </th>
                  <th
                    className="px-5 py-3  text-center text-gray-600 font-semibold bg-white border-b border-gray-200  text-nowrap"
                    scope="col"
                  >
                    {t("orderList.note")}
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <SkeletonTheme baseColor="#ebebeb" highlightColor="#c9c9c9">
                  <tbody className="font-normal text-center">
                    {new Array(10).fill(1).map((_, index) => (
                      <MockSkeleton key={`MockSkeleton-${index}`} index={index} />
                    ))}
                  </tbody>
                </SkeletonTheme>
              ) : tableData?.values?.length === 0 || !tableData?.values ? (
                <tbody >
                  <tr
                  >
                    <td colSpan={8} className="text-center py-5">
                      <div key="empty-list">
                        <EmptyListPlaceholderContent
                          title={t("orderList.noOrdersTitle")}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="font-normal text-center">
                  {tableData?.values?.map((value: any, index: any) => (
                    <tr
                      key={`tr-${index}-${value.id}`}
                      className="cursor-pointer hover:bg-gray-100"
                      onClick={() => navigate("/orders/" + value?.id)}
                    >
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {calculateItemNumber(index) || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 cursor-pointer "
                      >
                        {value?.number || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {value.updatedAt || value.signingAt || value.createdAt}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center w-[225px]">
                        <div className="flex justify-center">
                          <StatusBadge
                            type={statusTypes[value.status]?.type || "unsaved"}
                            fontSize="15px"
                            circleSize="8px"
                          >
                            {statusTypes[value.status]?.label ||
                              t("documents_upload.unknownStatus")}
                          </StatusBadge>
                        </div>
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center">
                        {value?.refReleaseMethod === 1
                          ? t("orderList.releaseMethod1")
                          : value?.refReleaseMethod === 2
                            ? t("orderList.releaseMethod2")
                            : value?.refReleaseMethod || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center">
                        {value?.marksAmount || "-"}
                      </td>
                      <td
                        className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600 text-center max-w-[140px] text-nowrap"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={
                          value?.note || t("documentLabels_3.no_note")
                        }
                      >
                        <div className="overflow-hidden text-overflow-ellipsis">
                          {value?.note || "-"}
                        </div>
                        <Tooltip id="my-tooltip" place="top" delayHide={1500} />
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ActionDropwdownCell
                          key={`cell-${index}-${value.id}-acitons`}
                          id={value.id}
                          actions={[
                            {
                              title: t("orderList.show"),
                              action: () => {
                                navigate("/orders/" + value?.id);
                              },
                            },
                            ...(value?.status === "DRAFT" && roleMatrix.roleMatrix.find(item => item.id === 2 && item.delete)
                              ? [
                                {
                                  title: t("orderList.delete"),
                                  action: (id: any) => {
                                    setAcceptModal({
                                      id: value.id,
                                      number: value.number,
                                    });
                                  },
                                },
                              ]
                              : []),

                          ]}
                          activeTooltipId={activeTooltipId}
                          onTooltipToggle={handleTooltipToggle}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <Pagination
            totalItems={tableData?.totalItems || 0}
            totalPages={tableData?.totalPages || 0}
            pagination={pagination}
            setPagination={setPagination}
          />
        </div>
      </div>
      <div className="z-[1000] relative	">
        <Tooltip
          isOpen={isFilterOpen}
          id="filter-type"
          className={classNames(style.wrapperTooltip, "drop-shadow-2xl")}
          openOnClick={true}
          clickable={true}
          noArrow={true}
          opacity={1}
          place={"bottom-end"}
          afterShow={() => setIsFilterOpen(true)}
          afterHide={() => setIsFilterOpen(false)}
        >
          <div onClick={(e) => e.stopPropagation()}>{renderFilterInputs()}</div>
        </Tooltip>
      </div>
    </>
  );
}

export default OrderList;
