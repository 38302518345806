import axios, { AxiosResponse } from "axios";
import { GetResponse, PostResponse } from "./response";
import i18n from "../../i18n";
import apiClient from "./apiClient";

interface LooseObject {
  [key: string]: any;
}

export const getClearanceDocTypeList = async (): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse> = await apiClient.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/reference/custom/clearance-doc-type/list`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const getDocMarksList = async (
  docId: string,
  page: number,
  size: number,
  sortDirection: "ASC" | "DESC" = "DESC",
): Promise<any> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      sortDirection,
    }).toString();

    const response: AxiosResponse<GetResponse> = await apiClient.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/product/list/${docId}?${params}`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const addMarks = async (
  docId: string,
  marks: number[],
): Promise<any> => {
  try {
    const response: AxiosResponse<PostResponse> = await apiClient.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/add-marks/${docId}`,
      marks,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const addMarksByOrder = async (
  docId: string,
  ordersIds: number[],
): Promise<any> => {
  const requestBody = {
    documentId: docId,
    orderIds: ordersIds,
  };
  try {
    const response: AxiosResponse<PostResponse> = await apiClient.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/add-marks-by-order-v2`,
      requestBody,
      {
        headers: {
          // "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const addMarksResult=async(
  docId: string,
):Promise<any>=>{
  try {
    const response: AxiosResponse<GetResponse> = await apiClient.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/search-add-marks-result/${docId}`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
}

export const getDocument = async (doc_id: string): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse> = await apiClient.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/get/${doc_id}`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const create = async (
  files: FormData,
  onUploadProgress: any,
): Promise<any> => {
  try {
    const response: AxiosResponse<PostResponse> = await apiClient.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/file/upload-files`,
      files,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
        onUploadProgress,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during create invoice:", error);
    throw error;
  }
};

export const edit = async (filename: string): Promise<any> => {
  try {
    const response: AxiosResponse<GetResponse> = await apiClient.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/file/download/${filename}`,
      {
        responseType: "blob",
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during authentication:", error);
    throw error;
  }
};

export const deleteDocument = async (docId: string): Promise<any> => {
  try {
    // const requestBody = {
    //   appId: appId,
    //   docId: docId,
    // };

    const response: AxiosResponse<any> = await apiClient.delete(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/delete/${docId}`,
      {
        // data: requestBody,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error during jewelry cards deletion:", error);
    throw error;
  }
};

interface Document {
  refDocType: number;
  refCountryExport: number;
  refCustomsClearanceDocType: number;
  customsClearanceRegNumber: string;
  customsClearanceRegAt: string; // Дата в виде строки
  refCustomsDecisionCode: number;
  customsDecisionAt: string;
  customsDecisionDocNumber: string;
  protocolNumber: string;
  protocolAt: string;
  expertNumber: string;
  expertAt: string;
  controlActNumber: string;
  controlActAt: string;
  note: string;
  documentPaperNumber: string;
  documentPaperAt: string;
  marks: number[] | null;
  id: number;
  orgId: number;
  createUser: number;
  createdAt: string;
  receiver: string;
  sender: string;
  status: any;
  docNumber: string;
}

// Определение интерфейса для ответа API с документами
interface DocumentsResponse {
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
  totalPages: number;
  documents: Document[];
}

const getDocumentsList = async (
  page: number = 1,
  size: number = 10,
  sortDirection: "ASC" | "DESC" = "DESC",
  objectData?: any,
): Promise<DocumentsResponse> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      sortDirection,
    }).toString();

    const response = await apiClient.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/list?${params}`,
      objectData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data as DocumentsResponse;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

export const editDocument = async (orderData: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await apiClient.put(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/edit`,
      orderData,
      {
        headers: {
          // "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during order editing:", error);
    throw error;
  }
};




export const deleteMarks = async (docId: any, marks: string[]) => {
  try {
    const response = await apiClient.delete(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/delete-marks/${docId}`,
      {
        data: marks,
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при удалении заказа:", error);
    throw error;
  }
};

export const downloadProblemMarksFile = async (doc_id: string) => {
  try {
    const response = await apiClient.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/download/${doc_id}`,
      {
        withCredentials: true,
        responseType: "blob", // Важно для скачивания файла
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при скачивании файла:", error);
    throw error;
  }
};

const addMarksByFile = async (docId: string, file: File): Promise<any> => {
  try {
    const response = await apiClient.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/add-marks-by-file/${docId}`,
      { file: file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data as DocumentsResponse;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

const addMarksByCodes = async (
  docId: string,
  markIdsOrMarkCodes: boolean,
  size: boolean,
  markIds?: number[],
  markCodes?: string[],
): Promise<any> => {
  const objectData = {
    markIdsOrMarkCodes: markIdsOrMarkCodes,
    size: size,
    markIds: markIds || null,
    markCodes: markCodes || null,
  };

  try {
    const response = await apiClient.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/add-marks-by-codes-v2/${docId}`,
      objectData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );

    return response.data as DocumentsResponse;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

const searchMarksByDocument = async (
  docId: string,
  page: number,
  size: number,
  sortDirection: string,
  productTypeCode?: any,
  status?: any,
  statusDateStart?: string,
  statusDateEnd?: string,
): Promise<any> => {
  const objectData: LooseObject = {};

  if (productTypeCode) {
    objectData.productTypeCode = productTypeCode;
  }
  if (status && status?.length !== 0) {
    objectData.status = status;
  }

  if (statusDateStart) {
    objectData.statusDateStart = statusDateStart;
  }

  if (statusDateEnd) {
    objectData.statusDateEnd = statusDateEnd;
  }


  const params = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    sortDirection,
  }).toString();

  try {
    const response = await apiClient.post(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/marks/list/${docId}?${params.toString()}`,
      objectData,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",

        },
        withCredentials: true,
      },
    );

    return response.data ;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

const getReceiverOrg = async (iinBin: string): Promise<any> => {
  try {
    const response = await apiClient.get(
      `${process.env.REACT_APP_URL_AUTH_SERVICE}/organisation/search-by-iin-bin/${iinBin}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",

        },
        withCredentials: true,
      },
    );

    return response.data as DocumentsResponse;
  } catch (error) {
    console.error("Error posting documents list:", error);
    throw error;
  }
};

export const viewAndPrintDocument = async (doc_id: any): Promise<Blob> => {
  try {
    const response: AxiosResponse<any> = await apiClient.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/report/${doc_id}`,
      {
        headers: {
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
          responseType: "blob",
        },
        withCredentials: true,
        responseType: "blob",
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при просмотре и печати документа:", error);
    throw error;
  }
};

export const downloadDocumentMarks = async (doc_id: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await apiClient.get(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/download/${doc_id}`,
      {
        // headers: {
        //   // "Accept-Language": "ru-RU",
        //   // responseType: "blob",
        //   // "Accept-Language": i18n.language,
        //   "Content-Type": "application/octet-stream",
        //   "Content-Disposition": "attachment",
        // },
        withCredentials: true,
        responseType: "blob",
        // responseType: "document"
      },
    );

    return response?.data;
  } catch (error) {
    console.error("Ошибка при просмотре и печати документа:", error);
    throw error;
  }
};


export const deleteMarksPersonalization = async (docId: any,productId:any,marks: number[]) => {
  try {
    const response = await apiClient.delete(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/product/marks/${docId}/${productId}`,
      {
        data: marks,
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Ошибка при удалении заказа:", error);
    throw error;
  }
};

export const editDocumentPersonalization = async (docId:any,productId:any,orderData: any): Promise<any> => {
  const marks = {'marks':orderData}
  try {
    const response: AxiosResponse<any> = await apiClient.put(
      `${process.env.REACT_APP_URL_DOCUMENTS_SERVICE}/documents/personalization/manual/product/mark/edit/${docId}/${productId}`,
      marks,
      {
        headers: {
          // "Content-Type": "application/json",
          "Accept-Language": i18n.language?.includes("ru") ? "ru-RU" : "kk-KZ",
        },
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error during order editing:", error);
    throw error;
  }
};

const DocumentsService = {
  addMarks,
  getDocumentsList,
  getDocument,
  create,
  edit,
  deleteDocument,
  editDocument,
  getClearanceDocTypeList,
  getDocMarksList,
  deleteMarks,
  addMarksByOrder,
  addMarksResult,
  downloadProblemMarksFile,
  addMarksByFile,
  addMarksByCodes,
  searchMarksByDocument,
  getReceiverOrg,
  viewAndPrintDocument,
  downloadDocumentMarks,
  deleteMarksPersonalization,
  editDocumentPersonalization,
};

export default DocumentsService;
