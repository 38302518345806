import classNames from "classnames";
import { format } from "date-fns";
import { Field, Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import DocumentsService, {
  viewAndPrintDocument,
} from "../../common/api/documentsApi";
import { GetReferenceService } from "../../common/api/ordersApi";
import ModalButton from "../../shared/Ui/Buttons/ModalButtons";
import Calendar from "../../shared/Ui/Calendar/Calendar";
import CheckBox from "../../shared/Ui/CheckBox/CheckBox";
import { EmptyListPlaceholderContent } from "../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../shared/Ui/ModalComponent/ModalWindow";
import SelectCustomFormik from "../../shared/Ui/SelectCustom/SelectCustomFormik";
import StatusBadge from "../../shared/Ui/StatusBadge/StatusBadge";
import ActionDropwdownCell from "../../shared/components/Table/ActionDropwdownCell";
import Pagination from "../../shared/components/Table/Pagination";
import { ITable } from "../../shared/components/Table/types";
import { useAppDispatch, useAppSelector } from "../../shared/hook";
import { deleteDataDocument } from "../../shared/slice/documentState";
import {
  AcceptXmlProduct,
  RevokeAct,
} from "../Documents/DocumentTransferAcceptance/utils/signature";
import style from "./index.module.css";
import MockSkeletonDocs from "./utils/MockSkeletonDocs";
import { submitXmlProduct } from "../../shared/signature/documentsSignature";
import CheckResult from "../Documents/Upload/CheckResults/CheckResult";
import PageNotRole from "../../shared/Ui/PageNotFound/PageNotRole";
import Loader from "../../shared/Ui/Loader/Loader";
import { getDescription } from "../../shared/documentUtils/dictionary";

export type DocsType = "INBOX" | "SENT" | "DRAFT" | "PERSONALIZATION" | "ALL";

interface IDocumentType {
  type: DocsType;
}

interface StatusType {
  type: string;
  label: string;
}

interface LooseObject {
  [key: string]: any;
}
type IdType = number | string; // Определите тип данных для ID
type DocumentID = string | number; // Определяем тип для ID документов

export const DocumentList: React.FC<IDocumentType> = ({ type }) => {
  const [failedModal, setFailedModal] = useState<DocumentID[]>([]);
  const [downloadArray, setDownloadArray] = useState<IdType[]>([]);
  const [activeTooltipId, setActiveTooltipId] = useState<number | null>(null);
  const handleTooltipToggle = (id: number) => {
    setActiveTooltipId((prevId) => (prevId === id ? null : id));
  };
  const [sort, setSort] = useState<"ASC" | "DESC">("DESC");
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [deleteArray, setDeleteArray] = useState<any>([]);
  const [mainCheckboxChecked, setMainCheckboxChecked] = useState(false);
  const [tableData, setTableData] = useState<ITable>();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  type PaginationState = {
    pageIndex: number;
    pageSize: number;
  };

  const [pagination, setPagination] = useState<PaginationState>(() => {
    // Попробуйте получить состояние пагинации из localStorage
    const savedPagination = localStorage.getItem('paginationDocuments');
    return savedPagination ? JSON.parse(savedPagination) : { pageIndex: 0, pageSize: pageSize };
  });
  useEffect(() => {
    // Сохраните состояние пагинации в localStorage при каждом изменении
    localStorage.setItem('paginationDocuments', JSON.stringify(pagination));
  }, [pagination]);
  const [open, setOpen] = useState<boolean>(false);
  const [isCheckOpen, setIsCheckOpen] = useState<any>(null);
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState<string | null>(null);
  const [errorModal, setErrorModal] = useState<any>(null);
  const userData = useAppSelector((state) => state.auth.userData);
  const onModalClose = () => setIsModalOpen(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRole, setIsLoadingRole] = useState(true);
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [releaseMethods, setReleaseMethods] = useState([]);
  const roleMatrix = useAppSelector(state => state.roleMatrix)
  const [role, setRole] = useState<any>(null);
  const [initialValues, setInitialValues] = useState({
    documentTypes: [],
    receiver: "",
    sender: "",
    docTypes: [],
    status: [],
  });
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const statusTypes: { [key: string]: StatusType } = {
    DRAFT: { type: "test", label: t("documentStatus.draft") },
    ERROR: { type: "error", label: t("documentStatus.error") },
    IN_PROCESSING: { type: "test", label: t("documentStatus.in_processing") },
    PROCESSED: { type: "active", label: t("documentStatus.processed") },
    IN_ACCEPTANCE: { type: "test", label: t("documentStatus.in_acceptance") },
    IN_CONFIRMATION: {
      type: "test",
      label: t("documentStatus.in_confirmation"),
    },
    WITHDRAWN: { type: "error", label: t("documentStatus.withdrawn") },
    REJECTED: { type: "error", label: t("documentStatus.rejected") },
    SIGNED_BY_RECIPIENT: {
      type: "active",
      label: t("documentStatus.signed_by_recipient"),
    },
  };


  useEffect(() => {
    onLoad();
  }, [pagination, location, sort, role]);


  useEffect(() => {
    const clearSelected = (e: any) => {
      if (!(e.target.id === "filter-type")) {
        setIsFilterOpen(false);
      }
    };
    window.addEventListener("click", clearSelected);

    return () => window.removeEventListener("click", clearSelected);
  }, []);

  const calculateItemNumber = (index: any) => {
    return pagination.pageIndex * pagination.pageSize + index + 1;
  };
  const toggleSort = () => {
    const newSortDirection = sort === "ASC" ? "DESC" : "ASC";
    setSort(newSortDirection);
  };

  async function onSearchOpen() {
    releaseMethods?.length === 0 &&
      (await GetReferenceService("app/release-methods/list")
        .then((response: any) => {
          const options =
            response &&
            response?.map((item: any) => ({
              value: item.id,
              label: item.name,
            }));
          setReleaseMethods(options);
        })
        .catch((error: any) => {
          console.error("Error fetching bank catalog list:", error);
        }));
  }

  async function onLoad() {
    setIsLoading(true);
    const locationPath = location.pathname; // Получаем текущий путь
    const requestData: LooseObject = {};

    if (locationPath.includes("drafts")) {

      const roleObj = () => { return roleMatrix?.roleMatrix?.find(item => item.id === 6) }
      setRole(roleObj)

      // Настройки для страницы "Черновики"
      requestData.status = ["DRAFT"];
      if (initialValues?.documentTypes?.length !== 0)
        requestData.docTypes = initialValues?.documentTypes;
      if (startDate) {
        requestData.creationDateStart = format(startDate, "dd.MM.yyyy");
        if (endDate) {
          requestData.creationDateEnd = format(endDate, "dd.MM.yyyy");
        } else {
          requestData.creationDateEnd = format(startDate, "dd.MM.yyyy");
        }
      }
      if (initialValues?.receiver?.length !== 0) {
        requestData.receiver = initialValues?.receiver;
      }

      if (initialValues?.sender?.length !== 0) {
        requestData.sender = initialValues?.sender;
      }
    } else if (locationPath.includes("all")) {

      const roleObj = () => { return roleMatrix?.roleMatrix?.find(item => item.id === 12) }
      setRole(roleObj)

      // Настройки для страницы "Документы - Все"
      requestData.status =
        initialValues?.status?.length !== 0
          ? initialValues?.status
          : [
            "ERROR",
            "IN_PROCESSING",
            "PROCESSED",
            "IN_ACCEPTANCE",
            "IN_CONFIRMATION",
            "WITHDRAWN",
            "REJECTED",
            "SIGNED_BY_RECIPIENT",
          ];

      if (initialValues?.documentTypes?.length !== 0) {
        requestData.docTypes = initialValues?.documentTypes;
      }
      if (startDate) {
        requestData.signingDateStart = format(startDate, "dd.MM.yyyy");
        if (endDate) {
          requestData.signingDateEnd = format(endDate, "dd.MM.yyyy");
        } else {
          requestData.signingDateEnd = format(startDate, "dd.MM.yyyy");
        }
      }
      if (initialValues?.receiver?.length !== 0) {
        requestData.receiver = initialValues?.receiver;
      }
      if (initialValues?.sender?.length !== 0) {
        requestData.sender = initialValues?.sender;
      }
      // requestData.sender = null;
    } else if (locationPath.includes("inbox")) {

      const roleObj = () => { return roleMatrix?.roleMatrix?.find(item => item.id === 4) }
      setRole(roleObj)


      // Настройки для страницы "Документы - Входящие"
      requestData.status =
        initialValues?.status?.length !== 0
          ? initialValues?.status
          : [
            "ERROR",
            "IN_PROCESSING",
            "PROCESSED",
            "IN_ACCEPTANCE",
            "IN_CONFIRMATION",
            "WITHDRAWN",
            "REJECTED",
            "SIGNED_BY_RECIPIENT",
          ];
      requestData.receiver = userData?.iinBin || null;
      requestData.docTypes =
        initialValues?.documentTypes?.length !== 0
          ? initialValues?.documentTypes
          : [1, 3, 4, 5];
      if (startDate) {
        requestData.signingDateStart = format(startDate, "dd.MM.yyyy");
        if (endDate) {
          requestData.signingDateEnd = format(endDate, "dd.MM.yyyy");
        } else {
          requestData.signingDateEnd = format(startDate, "dd.MM.yyyy");
        }
      }
      if (initialValues?.sender?.length !== 0) {
        requestData.sender = initialValues?.sender;
      }
    } else if (locationPath.includes("sent")) {

      const roleObj = () => { return roleMatrix?.roleMatrix?.find(item => item.id === 5) }
      setRole(roleObj)

      // Настройки для страницы "Документы - Исходящие"
      requestData.status =
        initialValues?.status?.length !== 0
          ? initialValues?.status
          : [
            "ERROR",
            "IN_PROCESSING",
            "PROCESSED",
            "IN_ACCEPTANCE",
            "IN_CONFIRMATION",
            "WITHDRAWN",
            "REJECTED",
            "SIGNED_BY_RECIPIENT",
          ];
      requestData.docTypes =
        initialValues?.documentTypes?.length !== 0
          ? initialValues?.documentTypes
          : [2, 5]; // Типы документов для исходящих
      if (startDate) {
        requestData.signingDateStart = format(startDate, "dd.MM.yyyy");
        if (endDate) {
          requestData.signingDateEnd = format(endDate, "dd.MM.yyyy");
        } else {
          requestData.signingDateEnd = format(startDate, "dd.MM.yyyy");
        }
      }
      if (initialValues?.receiver?.length !== 0) {
        requestData.receiver = initialValues?.receiver;
      }
      requestData.sender = userData?.iinBin || null;
    } else if (locationPath.includes("personalization")) {

      const roleObj = () => { return roleMatrix?.roleMatrix?.find(item => item.id === 7) }
      setRole(roleObj)

      // Настройки для страницы "Документы - Персонализация"
      requestData.status =
        initialValues?.status?.length !== 0
          ? initialValues?.status
          : [
            "ERROR",
            "IN_PROCESSING",
            "PROCESSED",
            "IN_ACCEPTANCE",
            "IN_CONFIRMATION",
          ];
      requestData.docTypes =
        initialValues?.documentTypes?.length !== 0
          ? initialValues?.documentTypes
          : [6];
      if (startDate) {
        requestData.signingDateStart = format(startDate, "dd.MM.yyyy");
        if (endDate) {
          requestData.signingDateEnd = format(endDate, "dd.MM.yyyy");
        } else {
          requestData.signingDateEnd = format(startDate, "dd.MM.yyyy");
        }
      }
      if (initialValues?.receiver?.length !== 0) {
        requestData.receiver = initialValues?.receiver;
      }
      // requestData.sender = userData?.iinBin || null;
    }

    setIsLoadingRole(false)

    try {
      const response = await DocumentsService.getDocumentsList(
        pagination.pageIndex + 1,
        pagination.pageSize,
        sort,
        requestData,
      );
      setTableData({
        values: response?.documents,
        currentPage: response?.currentPage,
        itemsPerPage: response?.itemsPerPage,
        totalItems: response?.totalItems,
        totalPages: response?.totalPages,
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setIsLoading(false);
    }
  }
  const deleteDocument = (docId: any) => {
    if (!docId) {
      setErrorModal(t("documentNotification.no_id_error"));
      setIsModalOpen(null);
      return;
    }
    const docIds = Array.isArray(docId) ? docId : [docId];
    Promise.all(
      docIds.map((id) =>
        DocumentsService.deleteDocument(id)
          .then(() => {
            // Успешное удаление
            console.log(`Документ с ID ${id} успешно удален`);
          })
          .catch((error) => {
            // Ошибка при удалении документа
            console.error(`Ошибка при удалении документа с ID ${id}:`, error);
            throw error; // Пробрасываем ошибку в Promise.all
          }),
      ),
    )
      .then(() => {
        // Все документы успешно удалены
        console.log("Все документы успешно удалены");
        // Проверка оставшихся документов на текущей странице
        const remainingDocsOnPage =
          (tableData?.values?.length || 0) - docIds?.length;
        if (
          remainingDocsOnPage === 0 &&
          tableData?.currentPage === tableData?.totalPages
        ) {
          setPagination((prevState) => ({
            ...prevState,
            pageIndex:
              prevState.pageIndex === 0
                ? prevState.pageIndex
                : prevState.pageIndex - 1,
          }));
        }
        setIsModalOpen(null);
        setDeleteArray([]);
        onLoad(); // Загрузка данных после удаления
      })
      .catch((error) => {
        // Ошибка при удалении одного из документов
        setErrorModal(
          t("documentNotification_3.delete_all_error", { error: error }),
        );
        setIsModalOpen(null);
      });
  };

  const handleDownloadClick = async (ids: any[]) => {
    // Устанавливаем начальное состояние
    setIsLoading(true);
    setFailedModal([]); // Очищаем массив с неудачными ID

    try {
      // Создаем массив промисов для скачивания всех файлов
      const downloadPromises = ids.map(async (id) => {
        try {
          // Получаем Blob файла для скачивания
          const fileBlob = await viewAndPrintDocument(id);
          const downloadUrl = window.URL.createObjectURL(fileBlob);

          // Создаем ссылку для скачивания
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `document_${id}.pdf`);

          // Добавляем ссылку на страницу, кликаем по ней и удаляем
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Освобождаем URL.createObjectURL
          window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
          console.error(`Ошибка при скачивании файла с ID ${id}:`, error);
          setFailedModal((prevFailed) => [...prevFailed, id]); // Добавляем ID в список неудач
        }
      });

      // Ждем выполнения всех промисов
      await Promise.all(downloadPromises);
    } catch (error) {
      console.error("Ошибка при скачивании файлов:", error);
    } finally {
      setIsLoading(false); // Останавливаем индикатор загрузки в любом случае
    }
  };
  const locationPath = location.pathname
  const renderActions = (
    value: any,
    setErrorModal: any,
    navigate: any,
    setAlertOpen?: any,
  ) => {
    const actions = [];
    actions.push({
      title: t("orderList.show"),
      action: () => {
        dispatch(deleteDataDocument());
        if (value.refDocType == "1") {
          navigate(`/documents/import-other-countries/${value?.id}`);
        } else if (value.refDocType == "2") {
          navigate(`/documents/inventory-write-off/${value?.id}`);
        } else if (value.refDocType == "3") {
          navigate(`/documents/inventory-resumption/${value?.id}`);
        } else if (value.refDocType == "4") {
          navigate(`/documents/import-eaeu/${value?.id}`);
        } else if (value.refDocType == "5") {
          navigate(`/documents/transfer-acceptance/${value?.id}`);
        } else if (value.refDocType == "6") {
          navigate(`/documents/personalization/${value?.id}`);
        }
      },
    });
    value?.status === "DRAFT" && role.delete &&
      actions.push({
        title: t("mainButtons.delete"),
        action: () => setIsModalOpen(value?.id),
      });

    if (
      value.status !== "DRAFT" &&
      value.status !== "new" &&
      value.status !== "IN_ACCEPTANCE" &&
      value.refDocType !== 5 &&
      value.refDocType !== 6 &&
      value.status !== "ERROR" &&
      value.status !== "IN_PROCESSING"
    ) {
      actions.push({
        title: t("mainButtons.print"),
        action: () => handleDownloadClick([value.id]),
      });
    }
    if (value.status === "SIGNED_BY_RECIPIENT" && value.refDocType === 5) {
      actions.push({
        title: t("mainButtons.print"),
        action: () => handleDownloadClick([value.id]),
      });
    }
    if (
      value?.status === "IN_CONFIRMATION" &&
      value.refDocType === 5 &&
      value?.receiver === userData?.iinBin
    ) {
      actions.push({
        title: t("mainButtons.accept"),
        action: () =>
          AcceptXmlProduct(value.id, "ACCEPT", setErrorModal, navigate),
      });
      actions.push({
        title: t("mainButtons_2.reject"),
        action: () =>
          AcceptXmlProduct(value.id, "REJECT", setErrorModal, navigate),
      });
    }
    if (
      value?.status === "IN_CONFIRMATION" &&
      value.refDocType === 5 &&
      value?.receiver !== userData?.iinBin
    ) {
      actions.push({
        title: t("mainButtons_2.revoke"),
        action: () => RevokeAct(value.id, setErrorModal, navigate),
      });
    }
    const locationPath = location.pathname;
    const isDraftOrInAcceptance = value.status === "DRAFT" || value.status === "IN_ACCEPTANCE";
    let specificActions = [];

    if (locationPath.includes("drafts")) {
      specificActions = role?.specificAction?.actions;
    } else if (locationPath.includes("inbox")) {
      specificActions = role?.specificAction?.documents?.[2]?.actions;
    } else if (locationPath.includes("sent") || locationPath.includes("personalization")) {
      specificActions = role?.specificAction?.actions;
    } else if (locationPath.includes("all")) {
      specificActions = role?.specificAction?.actions;
    }

    if (isDraftOrInAcceptance && specificActions?.length > 0 && specificActions[0].active) {
      actions.push({
        title: t("mainButtons.sign"),
        action: () => {
          setActiveId(value.id);
          submitXmlProduct(
            value.id,
            navigate,
            setAlertOpen,
            setIsLoading,
            setErrorModal,
            setIsCheckOpen,
            t
          );
        },
      });
    }
    return actions;
  };

  const handleMainCheckboxChange = () => {
    // Проверка на наличие данных
    if (!tableData?.values) return;

    // Получаем все ID из таблицы
    const allIds = tableData.values.map((value) => value.id);

    // Выбор массива в зависимости от пути
    const currentArray = location.pathname === '/documents/inbox' || location.pathname === '/documents/sent'
      ? downloadArray
      : deleteArray;

    // Функция установки массива
    const setArray = location.pathname === '/documents/inbox' || location.pathname === '/documents/sent'
      ? setDownloadArray
      : setDeleteArray;

    // Переключение состояния чекбокса
    if (currentArray.length === allIds.length) {
      setArray([]); // Убираем отметку со всех
    } else {
      setArray(allIds); // Отмечаем все
    }
  };

  const handleCheckboxChange = (id: any) => {
    if (location.pathname === '/documents/inbox' || location.pathname === '/documents/sent') {
      // Работаем с downloadArray для путей '/inbox' и '/sent'
      if (downloadArray.includes(id)) {
        setDownloadArray(downloadArray.filter((item: any) => item !== id));
      } else {
        setDownloadArray([...downloadArray, id]);
      }
    } else {
      // Работаем с deleteArray для всех остальных путей
      if (deleteArray.includes(id)) {
        setDeleteArray(deleteArray.filter((item: any) => item !== id));
      } else {
        setDeleteArray([...deleteArray, id]);
      }
    }
  };

  const getNameByType = (type: any) => {
    if (type === 'INBOX') return t("submenu.inbox");
    if (type === 'SENT') return t("submenu.sent");
    if (type === 'DRAFT') return t("submenu.drafts");
    if (type === 'PERSONALIZATION') return t("submenu.personalization");
    return t("documentLabels_3.documents");
  };

  const renderStatusOptions = () => {
    const locationPath = location.pathname; // Получаем текущий путь

    if (locationPath.includes("drafts")) {
      return [{ value: "DRAFT", label: t("documentStatus.draft") }];
    } else if (locationPath.includes("inbox")) {
      return [
        { value: "ERROR", label: t("documentStatus.error") },
        { value: "IN_PROCESSING", label: t("documentStatus.in_processing") },
        { value: "PROCESSED", label: t("documentStatus.processed") },
        {
          value: "IN_ACCEPTANCE",
          label: t("documentStatus.in_acceptance"),
        },
        {
          value: "IN_CONFIRMATION",
          label: t("documentStatus.in_confirmation"),
        },
        { value: "WITHDRAWN", label: t("documentStatus.withdrawn") },
        { value: "REJECTED", label: t("documentStatus.rejected") },
        {
          value: "SIGNED_BY_RECIPIENT",
          label: t("documentStatus.signed_by_recipient"),
        },
      ];
    } else if (locationPath.includes("sent")) {
      return [
        { value: "ERROR", label: t("documentStatus.error") },
        { value: "IN_PROCESSING", label: t("documentStatus.in_processing") },
        { value: "PROCESSED", label: t("documentStatus.processed") },
        {
          value: "IN_ACCEPTANCE",
          label: t("documentStatus.in_acceptance"),
        },
        {
          value: "IN_CONFIRMATION",
          label: t("documentStatus.in_confirmation"),
        },
        { value: "WITHDRAWN", label: t("documentStatus.withdrawn") },
        { value: "REJECTED", label: t("documentStatus.rejected") },
        {
          value: "SIGNED_BY_RECIPIENT",
          label: t("documentStatus.signed_by_recipient"),
        },
      ];
    } else if (locationPath.includes("personalization")) {
      return [
        { value: "ERROR", label: t("documentStatus.error") },
        { value: "IN_PROCESSING", label: t("documentStatus.in_processing") },
        { value: "PROCESSED", label: t("documentStatus.processed") },
      ];
    } else if (locationPath.includes("all")) {
      return [
        { value: "ERROR", label: t("documentStatus.error") },
        { value: "IN_PROCESSING", label: t("documentStatus.in_processing") },
        { value: "PROCESSED", label: t("documentStatus.processed") },
        {
          value: "IN_ACCEPTANCE",
          label: t("documentStatus.in_acceptance"),
        },
        {
          value: "IN_CONFIRMATION",
          label: t("documentStatus.in_confirmation"),
        },
        { value: "WITHDRAWN", label: t("documentStatus.withdrawn") },
        { value: "REJECTED", label: t("documentStatus.rejected") },
        {
          value: "SIGNED_BY_RECIPIENT",
          label: t("documentStatus.signed_by_recipient"),
        },

      ];
    }

    return [];
  };

  const renderDocumentTypeOptions = () => {
    const locationPath = location.pathname; // Получаем текущий путь

    if (locationPath.includes("drafts")) {
      return [
        {
          value: 1,
          label: t("documentType.notification_from_third_countries"),
        },
        { value: 2, label: t("documentType.withdrawal_notification") },
        { value: 3, label: t("documentType.re_introduction_notification") },
        { value: 4, label: t("documentType.notification_from_eaes") },
        { value: 5, label: t("documentType.goods_transfer_act") },
        { value: 6, label: t("submenu.personalization") }
      ];
    } else if (locationPath.includes("inbox")) {
      return [
        {
          value: 1,
          label: t("documentType.notification_from_third_countries"),
        },
        { value: 3, label: t("documentType.re_introduction_notification") },
        { value: 4, label: t("documentType.notification_from_eaes") },
        { value: 5, label: t("documentType.goods_transfer_act") },
      ];
    } else if (locationPath.includes("sent")) {
      return [
        { value: 2, label: t("documentType.withdrawal_notification") },
        { value: 5, label: t("documentType.goods_transfer_act") },
      ];
    } else if (locationPath.includes("all")) {
      return [
        {
          value: 1,
          label: t("documentType.notification_from_third_countries"),
        },
        { value: 2, label: t("documentType.withdrawal_notification") },
        { value: 3, label: t("documentType.re_introduction_notification") },
        { value: 4, label: t("documentType.notification_from_eaes") },
        { value: 5, label: t("documentType.goods_transfer_act") },
        { value: 6, label: t("submenu.personalization") }
      ];
    }
    return [];
  };

  if (!role && isLoadingRole) return <Loader />;

  if (!role || !role?.view) {
    return (
      <PageNotRole />
    )
  }

  const renderFilterInputs = () => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          setPagination((prevValues: PaginationState) => {
            return { ...prevValues, pageIndex: 0 };
          });
          setIsFilterOpen(false);
        }}
        // enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {(formik) => (
          <Form className="max-w-[1585px] w-full m-t-[20px]">
            <div className="flex flex-row align-center justify-between">
              <p className="my-3 text-lg font-semibold">
                {t("documents_upload.filters")}
              </p>
              <button
                type="reset"
                onClick={() => {
                  setIsFilterOpen(false);
                }}
              >
                <img src="/icons/close.svg" />
              </button>
            </div>
            {!location.pathname.includes("personalization") && (

              <div className={style.inputField}>
                <div className={style.labelAndButtonWrapper}>
                  <label className={style.inputLabel}>
                    {t("documentLabels_3.document_label")}
                  </label>
                </div>
                <Field
                  value={formik?.values?.documentTypes || null}
                  component={SelectCustomFormik}
                  name="documentTypes"
                  placeholder={t("documentLabels.choose")}
                  isClearable={true}
                  isMulti={true}
                  size="dm"
                  options={renderDocumentTypeOptions()}
                  onChange={(options: any) => {
                    formik.setFieldValue("documentTypes", options);
                    setInitialValues((prevValues: any) => {
                      return { ...prevValues, documentTypes: options };
                    });
                  }}
                />
              </div>
            )}

            {!location?.pathname.includes("inbox") &&
              !location.pathname.includes("personalization") &&
              (
                <div className={style.inputField}>
                  <div className={style.labelAndButtonWrapper}>
                    <label className={style.inputLabel}>
                      {t("documentLabels_3.receiver_label")}
                    </label>
                  </div>
                  <Field
                    type="text"
                    name="receiver"
                    placeholder={t("documentLabels.choose_option")}
                    className={style.placeholder}
                    value={formik?.values?.receiver}
                    onChange={(e: any) => {
                      formik.setFieldValue("receiver", e.target.value);
                      setInitialValues((prevValues: any) => {
                        return { ...prevValues, receiver: e.target.value };
                      });
                    }}
                  />
                </div>
              )}

            {locationPath.includes("all") && (
              <div className={style.inputField}>
                <div className={style.labelAndButtonWrapper}>
                  <label className={style.inputLabel}>
                    {t("documentLabels_3.sender")}
                  </label>
                </div>
                <Field
                  type="text"
                  name="sender"
                  placeholder={t("documentLabels.choose_option")}
                  className={style.placeholder}
                  value={formik?.values?.sender}
                  onChange={(e: any) => {
                    formik.setFieldValue("sender", e.target.value);
                    setInitialValues((prevValues: any) => {
                      return { ...prevValues, sender: e.target.value };
                    });
                  }}
                />
              </div>

            )}

            <div className={style.inputField}>
              <div className={style.labelAndButtonWrapper}>
                <label className={style.inputLabel}>
                  {t("documentLabels_3.status_processing")}
                </label>
              </div>
              <Field
                value={formik?.values?.status || null}
                component={SelectCustomFormik}
                name="status"
                placeholder={t("documentLabels.choose")}
                isClearable={true}
                isMulti={true}
                size="dm"
                options={renderStatusOptions()}
                onChange={(options: any) => {
                  formik.setFieldValue("status", options);
                  setInitialValues((prevValues: any) => {
                    return { ...prevValues, status: options };
                  });
                }}
              />
            </div>

            <Calendar
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            <div className="flex flex-row gap-[10px]">
              <button
                type="submit"
                className=" bg-green text-white font-semibold py-2 px-4 rounded"
              >
                {t("mainButtons3.apply")}
              </button>
              <button
                type="button"
                className=" bg-bg text-gray-500 font-semibold py-2 px-4 rounded"
                onClick={() => {
                  setStartDate(undefined);
                  setEndDate(undefined);
                  setInitialValues({
                    documentTypes: [],
                    receiver: "",
                    sender: "",
                    docTypes: [],
                    status: [],
                  });
                  formik.resetForm({
                    values: {
                      documentTypes: [],
                      receiver: "",
                      sender: "",
                      docTypes: [],
                      status: [],
                    },
                  });
                  setPagination((prevValues: PaginationState) => {
                    return { ...prevValues, pageIndex: 0 };
                  });
                }}
              >
                {t("mainButtons3.reset")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <div className="flex flex-1 flex-col min-w-0">
      <h2 className="my-3 text-xl font-semibold">
        {getNameByType(type)}
      </h2>
      <div className="bg-white  my-5 p-5  pt-8 relative rounded-xl shadow-md flex flex-row align-center justify-between">
        {role.add ? (
          <button
            className=" bg-green text-white font-semibold py-2 px-4 rounded"
            data-tooltip-offset={4}
            data-tooltip-id="document-type"
          >
            {t("documentLabels_3.create_document")}
          </button>
        ) : <div></div>}

        <div className="flex flex-row gap-[10px]">
          {location.pathname === "/documents/drafts" &&
            deleteArray?.length > 0 && (
              <button
                onClick={(e) => {
                  setIsModalOpen(deleteArray);
                }}
                className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                type="button"
              >
                {t("documentLabels_3.delete_selected")}
              </button>
            )}
          {(location.pathname === '/documents/inbox' || location.pathname === '/documents/sent') &&
            downloadArray?.length > 0 && (
              <button
                onClick={(e) => {
                  handleDownloadClick(downloadArray)
                }}
                className="bg-bg hover:brightness-75 text-gray-500 font-bold py-2 px-4 rounded"
                type="button"
              >
                Скачать выбранное
              </button>
            )}
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onSearchOpen();
              setIsFilterOpen(true);
            }}
            className=" bg-green text-white font-semibold py-2 px-4 rounded"
            data-tooltip-offset={4}
            data-tooltip-id="filter-type"
          >
            {t("mainButtons3.filter")}
          </button>
          <button
            onClick={() => {
              setStartDate(undefined);
              setEndDate(undefined);
              setInitialValues({
                documentTypes: [],
                receiver: "",
                sender: "",
                docTypes: [],
                status: [],
              });
              setPagination((prevValues: PaginationState) => {
                return { ...prevValues, pageIndex: 0 };
              });
            }}
          >
            <img src="/icons/update.svg" />
          </button>
        </div>
      </div>

      <div className="w-full">
        <div className="rounded-lg overflow-x-auto bg-white">
          <table className="max-w-full w-full leading-normal">
            <thead className="border-b-2 border-gray-300">
              <tr>
                {(tableData?.totalItems !== 0 && !locationPath.includes("personalization")) && (
                  <th>
                    <CheckBox
                      id="mainCheckbox"
                      checked={
                        deleteArray?.length === tableData?.values?.length ||
                        downloadArray?.length === tableData?.values?.length
                      }
                      onChange={handleMainCheckboxChange}
                    />
                  </th>
                )}
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  №{t("documents_upload.serialNumber")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documentLabels_3.document_number")}
                </th>
                <th
                  className="px-5 py-3 text-center items-center text-gray-600 font-semibold bg-white border-b border-gray-200 flex justify-center"
                  scope="col"
                >
                  {t("documentLabels_3.document_date_time")}
                  <div className="flex flex-col">
                    <button className="h-6 w-6" onClick={toggleSort}>
                      <img
                        src={`/icons/arrow${sort === "ASC" ? "Down2" : "-top"}.svg`}
                        className={`block h-6 w-6  ${sort === "ASC" ? "text-red-500" : ""}`}
                        alt={sort === "ASC" ? "Ascending" : "Descending"}
                      />
                    </button>
                  </div>
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documentLabels_3.document_name")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documentLabels_3.receiver")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documentLabels_3.sender")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documentLabels_3.processing_status")}
                </th>
                <th
                  className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                  scope="col"
                >
                  {t("documentLabels_3.note")}
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <SkeletonTheme baseColor="#ebebeb" highlightColor="#c9c9c9">
                <tbody className="font-normal text-center">
                  {new Array(10).fill(1).map((_, index) => (
                    <MockSkeletonDocs key={index} index={index} />
                  ))}
                </tbody>
              </SkeletonTheme>
            ) : tableData?.values?.length === 0 || !tableData?.values ? (
              <tbody>
                <tr
                >
                  <td colSpan={8} className="text-center py-5">
                    <div>
                      <EmptyListPlaceholderContent
                        title={t("documentLabels_3.no_documents")}
                        description={t("documentLabels_3.no_matching_data")}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="font-normal text-center">
                {tableData?.values?.map((value, index) => (
                  <>
                    <tr
                      key={`tr-${index}-${value.id}`}
                      className="cursor-pointer hover:bg-gray-100"
                      onClick={() => {


                        if (value.refDocType == "1") {
                          navigate(`/documents/import-other-countries/${value?.id}`);
                        } else if (value.refDocType == "2") {
                          navigate(`/documents/inventory-write-off/${value?.id}`);
                        } else if (value.refDocType == "3") {
                          navigate(`/documents/inventory-resumption/${value?.id}`);
                        } else if (value.refDocType == "4") {
                          navigate(`/documents/import-eaeu/${value?.id}`);
                        } else if (value.refDocType == "5") {
                          navigate(`/documents/transfer-acceptance/${value?.id}`);
                        } else if (value.refDocType == "6") {
                          navigate(`/documents/personalization/${value?.id}`);
                        }
                      }}
                    >
                      {(!locationPath.includes("personalization")) && (
                        <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                          <CheckBox
                            id={`checkBox${index}`}
                            checked={deleteArray.includes(value.id) || downloadArray.includes(value.id)}
                            onChange={() => handleCheckboxChange(value.id)}
                          />
                        </td>
                      )
                      }
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {calculateItemNumber(index) || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {value?.docNumber || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {location?.pathname.includes("draft")
                          ? value?.createdAt &&
                          format(value?.createdAt, "dd.MM.yyyy, HH:mm")
                          : (value?.signingAt &&
                            format(value?.signingAt, "dd.MM.yyyy, HH:mm")) ||
                          "-"}
                      </td>
                      <td className="px-5 py-5 text-sm border-b border-gray-200 text-gray-600">
                        {getDescription(value.refDocType,t)}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                        {value?.receiver || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600">
                        {value?.sender || "-"}
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 text-center">
                        <div className="flex justify-center">
                          <StatusBadge
                            fontSize="13px"
                            circleSize="8px"
                            type={statusTypes[value.status]?.type || "unsaved"}
                          >
                            {statusTypes[value.status]?.label ||
                              t("documents_upload.unknownStatus")}
                          </StatusBadge>
                        </div>
                      </td>
                      <td
                        className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600 text-center max-w-[140px] text-nowrap"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={
                          value?.note || t("documentLabels_3.no_note")
                        }
                      >
                        <div className="overflow-hidden text-overflow-ellipsis">
                          {value?.note || "-"}
                        </div>
                        <Tooltip
                          id="my-tooltip"
                          place="top"
                          delayHide={1500}
                          className="whitespace-pre-wrap max-w-[250px] break-words"
                        />
                      </td>
                      <td className="px-5 py-5 text-sm  border-b border-gray-200 text-gray-600"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ActionDropwdownCell
                          key={`cell-${index}-${value.id}-actions`}
                          id={value.id}
                          actions={renderActions(
                            value,
                            setErrorModal,
                            navigate,
                          )}
                          activeTooltipId={activeTooltipId}
                          onTooltipToggle={handleTooltipToggle}
                        />
                      </td>
                    </tr>
                    <CheckResult
                      isOpen={isCheckOpen && value.id === activeId}
                      setIsOpen={setIsCheckOpen}
                      problemMarks={isCheckOpen}
                      docId={value.id}
                    />
                  </>
                ))}
              </tbody>
            )}
          </table>
        </div>
        <Pagination
          totalItems={tableData?.totalItems || 0}
          totalPages={tableData?.totalPages || 0}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
      <div className="z-[1000] relative	">
        <Tooltip
          isOpen={isFilterOpen}
          id="filter-type"
          className={classNames(style.wrapperTooltipFilter, "drop-shadow-2xl")}
          openOnClick={true}
          clickable={true}
          noArrow={true}
          opacity={1}
          place={"bottom-end"}
          afterShow={() => setIsFilterOpen(true)}
          afterHide={() => setIsFilterOpen(false)}
        >
          <div onClick={(e) => e.stopPropagation()}>{renderFilterInputs()}</div>
        </Tooltip>
        <Tooltip
          id="document-type"
          className={classNames(style.wrapperTooltip, "drop-shadow-2xl")}
          openOnClick={true}
          clickable={true}
          noArrow={true}
          opacity={1}
          place={"bottom-end"}
          afterShow={() => {
            setOpen(true);
          }}
          afterHide={() => {
            setOpen(false);
          }}
        >
          <div
            className={classNames(
              style.tooltip,
              "flex flex-col min-w-[208px] p-0 rounded-lg relative left-[21px] overflow-hidden",
            )}
          >
            <span className={classNames("flex h-10 items-center pl-4 text-sm")}>
              {t("documentLabels_3.choose_document_type")}
            </span>
            {(!location?.pathname.includes("sent") && !location?.pathname.includes("personalization")) && (
              <button
                className={classNames(
                  "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                )}
                onClick={() => {
                  dispatch(deleteDataDocument());
                  navigate("/documents/import-other-countries/new");
                }}
              >
                <span>
                  {t("documentType.notification_from_third_countries")}
                </span>
              </button>
            )}
            {(!location?.pathname.includes("sent") && !location?.pathname.includes("personalization")) && (
              <button
                className={classNames(
                  "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                )}
                onClick={() => {
                  dispatch(deleteDataDocument());
                  navigate("/documents/import-eaeu/new");
                }}
              >
                <span>{t("documentType.notification_from_eaes")}</span>
              </button>
            )}
            {(!location?.pathname.includes("sent") && !location?.pathname.includes("personalization")) && (
              <button
                className={classNames(
                  "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                )}
                onClick={() => {
                  dispatch(deleteDataDocument());
                  navigate("/documents/inventory-resumption/new");
                }}
              >
                <span>{t("documentType.re_introduction_notification")}</span>
              </button>
            )}
            {(!location?.pathname.includes("inbox") && !location?.pathname.includes("personalization")) && (
              <button
                type="button"
                className={classNames(
                  "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                )}
                onClick={() => {
                  dispatch(deleteDataDocument());
                  navigate("/documents/inventory-write-off/new");
                }}
              >
                <span>{t("documentType.withdrawal_notification")}</span>
              </button>
            )}
            {(!location?.pathname.includes("inbox") && !location?.pathname.includes("personalization")) && (
              <button
                className={classNames(
                  "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                )}
                onClick={() => {
                  dispatch(deleteDataDocument());
                  navigate("/documents/transfer-acceptance/new");
                }}
              >
                <span>{t("documentType.goods_transfer_act")}</span>
              </button>
            )}
            {(location?.pathname.includes("personalization") || location?.pathname.includes("draft")) && (
              <button
                className={classNames(
                  "flex h-10 items-center px-4 duration-200 text-sm justify-between",
                )}
                onClick={() => {
                  dispatch(deleteDataDocument());
                  navigate("/documents/personalization/new");
                }}
              >
                <span>{t("productPersonalization.title")}</span>
              </button>
            )}
          </div>
        </Tooltip>
      </div>
      <ModalComponent
        isOpen={!!isModalOpen}
        onClose={onModalClose}
        headerContent={
          <h1 className="h__modal">{t("documentNotification.notification")}</h1>
        }
        content={
          <p className="p__modal">
            {t("documentLabels_3.confirm_delete")}
            {Array.isArray(isModalOpen) && isModalOpen?.length > 1
              ? t("documentLabels_3.selected_documents")
              : t("documentLabels_3.selected_document")}
            ?
          </p>
        }
        customContent={
          <div className="flex flex-row gap-[10px]">
            <ModalButton
              type="button"
              color="grey"
              onClick={() => {
                setIsModalOpen(null);
              }}
            >
              {t("mainButtons.cancel")}
            </ModalButton>
            <ModalButton
              type="button"
              onClick={async () => {
                if (isModalOpen) {
                  deleteDocument(isModalOpen);
                }
              }}
            >
              {t("mainButtons.delete")}
            </ModalButton>
          </div>
        }
      />
      <ModalComponent
        isOpen={!!(failedModal && failedModal.length > 0)}
        onClose={onModalClose}
        headerContent={
          <h1 className="h__modal">{t("documentNotification.notification")}</h1>
        }
        content={
          <p className="p__modal ">
            У следующих документов обнаружен неправильный статус:
            <ul>
              {failedModal?.map((item, index) => (
                <li key={index} className="underline-offset-4">{item}</li>
              ))}
            </ul>

          </p>
        }
        customContent={
          <div className="flex flex-row gap-[10px]">
            <ModalButton
              type="button"
              color="grey"
              onClick={() => {
                setFailedModal([])
              }}
            >
              {t("mainButtons.continue")}
            </ModalButton>

          </div>
        }
      />
      <ModalComponent
        isOpen={errorModal}
        onClose={() => {
          setErrorModal(null);
        }}
        headerContent={
          <h1 className="h__modal">
            {t("documentNotification.notification")}
          </h1>
        }
        content={
          <div className="error-list">
            <ul>
              {Array.isArray(errorModal) && errorModal.length > 0 ? (
                errorModal.map((error: any, index: number) => {
                  console.log("Error data:", error);
                  // Если error — это объект, извлекаем ключи или значения
                  const errorText = typeof error === "object" ? JSON.stringify(error) : error;

                  return (
                    <li className="border-b border-gray-300 py-1" key={index}>
                      Ошибка:  {t(`error_dictionary.${errorText}`)}
                    </li>
                  );
                })
              ) : typeof errorModal === "object" && errorModal !== null ? (
                // Обработка случая, когда errorModal является объектом
                errorModal.errorMessage ? (
                  <li className="border-b border-gray-300 py-1">
                    {t('documentStatus.error')}: {errorModal.errorMessage}
                  </li>
                ) : (
                  <li>{t("documentNotification.no_errors")}</li>
                )
              ) : (
                <li>{t("documentNotification.no_errors")}</li>
              )}
            </ul>
          </div>



        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
      />
    </div>
  );
};

export default DocumentList;
