import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import style from './index.module.css';
import { normalizedLocale } from '../../../../common/libs/normalizedLocale';
import { useTranslation } from 'react-i18next';

interface QrScannerComponentProps {
  onClose: () => void; // Функция для закрытия сканера
  onScanResult: (result: string) => void; // Функция для обработки результата сканирования
}

const QrScannerComponent: React.FC<QrScannerComponentProps> = ({ onClose, onScanResult }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  // const [scanResult, setScanResult] = useState<string | null>(null);
  const { t, i18n } = useTranslation();
  const locale = normalizedLocale(i18n.language);

  const [zoomValue, setZoomValue] = useState<number>(1); // Начальное значение зума
  const [maxZoom, setMaxZoom] = useState<number>(2); // Максимальный зум

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();
  
    async function startScanner() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment', // Камера по умолчанию (задняя камера)
          },
        });
  
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
  
        const track = stream.getVideoTracks()[0];
  
        // Проверяем поддержку зума
        if (typeof track.getCapabilities === 'function') {
          const capabilities = track.getCapabilities() as any;
          if (capabilities.zoom) {
            setMaxZoom(capabilities.zoom.max || 2); // Устанавливаем максимальный зум
          }
        }
  
        // Запуск сканера
        codeReader.decodeFromVideoDevice(null, videoRef.current!, (result, err) => {
          if (result) {
            onScanResult(result.getText());
          }
  
          if (err && !(err instanceof NotFoundException)) {
            console.error(err);
          }
        });
      } catch (err) {
        console.error('Error accessing camera:', err);
      }
    }
  
    startScanner();
  
    return () => {
      codeReader.reset(); // Сброс сканера
      if (videoRef.current?.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [onScanResult]);

  // Обновление зума
  const handleZoomChange = (value: number) => {
    setZoomValue(value);
    const stream = videoRef.current?.srcObject as MediaStream;
    const track = stream.getVideoTracks()[0];
    track
      .applyConstraints({
        advanced: [{ zoom: value }] as any,
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={style.fullscreenContainer}>
      <div className={style.topBar}>
        <img
          src={`/icons/e-track-logo${locale === 'kk' ? '-kz' : ''}.svg`}
          alt="Logo"
          className={style.etrackLogo}
          width={100}
          height={26}
        />
        <button className={style.closeButton} onClick={onClose}>
          &times;
        </button>
      </div>
      <div className={style.videoContainer}>
        <video ref={videoRef} className={style.video} />
        <div className={style.scanArea}>
          <div className={`${style.corner} ${style['top-left']}`} />
          <div className={`${style.corner} ${style['top-right']}`} />
          <div className={`${style.corner} ${style['bottom-left']}`} />
          <div className={`${style.corner} ${style['bottom-right']}`} />
        </div>
      </div>

      {maxZoom > 1 && (
        <div className={style.zoomContainer}>
          <input
            style={{ zIndex: 100000 }}
            type="range"
            id="zoom"
            min="1"
            max={maxZoom}
            step="0.1"
            value={zoomValue}
            onChange={(e) => handleZoomChange(Number(e.target.value))}
          />
        </div>
      )}

    </div>
  );
};

export default QrScannerComponent;
