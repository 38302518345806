import { PaginationState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import DocumentsService from "../../../../common/api/documentsApi";
import ModalButton from "../../../../shared/Ui/Buttons/ModalButtons";
import { EmptyListPlaceholderContent } from "../../../../shared/Ui/EmptyPageBlock";
import ModalComponent from "../../../../shared/Ui/ModalComponent/ModalWindow";
import StatusBadge from "../../../../shared/Ui/StatusBadge/StatusBadge";
import Pagination from "../../../../shared/components/Table/Pagination";
import { ITable } from "../../../../shared/components/Table/types";
import { useAppSelector } from "../../../../shared/hook";
import { generateAndDownloadExcel } from "../../../../shared/hooks/generateFiles";
import style from "./MarkScan.module.css";
import { addMarksToProduct } from "../../../../common/api/nktApi";
import AlertBox from "../../../../shared/Ui/AlertBox/AlertBox";
import { getMarkStatusType } from "../../../../shared/documentUtils/dictionary";
 
export interface IMarkScan {
  documentId: string | undefined;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggleOnLoad: boolean;
  setToggleOnLoad: Dispatch<SetStateAction<boolean>>;
  personalization?: any;
}

export const MarkScan: React.FC<IMarkScan> = (props) => {
  const { t, i18n } = useTranslation();
  const [alertOpen, setAlertOpen] = useState<any>(null);
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [resultsTableData, setResultsTableData] = useState<ITable>();
  const [resultsPagination, setResultsPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [errorModal, setErrorModal] = useState<any>(null);
  const [marks, setMarks] = useState<string>("");

  const onModalClose = () => props.setIsOpen(false);

  const [resultsModalOpen, setResultsModalOpen] = useState<boolean>(false);
  const [isResultsLoading, setIsResultsLoading] = useState<boolean>(false);

  const onResultsModalOpen = () => setResultsModalOpen(true);
  const onResultsModalClose = () => setResultsModalOpen(false);

  async function onLoadResults() {
    onResultsModalOpen();
    setIsResultsLoading(true);
    const marksArray = marks.split(/\s+/)?.filter((str) => str !== "");
    if (props.documentId) {
      const request = props.personalization 
        ? addMarksToProduct(props.personalization.documentId, props.personalization.productId, undefined, marksArray)
        : DocumentsService.addMarksByCodes(
          props.documentId,
          true,
          true,
          undefined,
          marksArray,
        );
  
      await request
        .then((response: any) => {
          const successMessage = `Успешно добавлено марок: ${(marksArray.length - response?.problemMarks) || '0'}`;
          if (response?.problemMarks?.length === 0) {
            setAlertOpen({
              status: marksArray.length - response?.problemMarks > 0 ? 'SUCCESS' : 'error',
              message: successMessage,
            });
            setIsResultsLoading(false);
            onResultsModalClose();
          } else {
            setAlertOpen({
              status: marksArray.length - response?.problemMarks > 0 ? 'SUCCESS' : 'error',
              message: successMessage,
            });
            // Установка данных для таблицы с проблемными марками
            setResultsTableData({
              values: response?.problemMarks,
              currentPage: 0,
              itemsPerPage: resultsPagination.pageSize,
              totalItems: response?.problemMarks?.length,
              totalPages: 1,
            });
          }
        })
        .catch((error: any) =>
          setErrorModal(
            `${error?.response?.data?.message}`,
          ),
        )
        .finally(() => {
          setIsResultsLoading(false);
          setMarks("");
          props.setToggleOnLoad(!props.toggleOnLoad);
        });
    }
  }
  

  const renderTextScan = () => {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col text-[20px] font-bold gap-[6px] text-gray-700 ">
          <p>{t("mark_scan.scan_codes")}</p>
        </div>

        <div className="flex flex-row items-center gap-[10px]">
          <img src="/icons/warning.svg" className="w-12 h-12" />
          <div>
            <p className="text-gray-500 font-semibold text-base">
              {t("mark_scan.scan_codes_description")}
            </p>
          </div>
        </div>

        <div>
          <textarea
            aria-multiline
            rows={4}
            cols={50}
            name="marks"
            placeholder={t("documentLabels.choose_option")}
            className={`${style.textareaPlaceholder} textarea-placeholder w-full h-[400px] min-h-[400px] border border-[#a3c0d2] rounded-lg px-4 pt-3 text-sm text-[#2e3138] font-medium leading-5 outline-none`}
            value={marks}
            onChange={(e: any) => setMarks(e.target.value)}
          />
        </div>
      </div>
    );
  };

  const generateExcelValues = (data: any) => {
    type TransformedObject = {
      [key: string]: any;
    };

    const results =
      data &&
      data?.map((item: any, index: number) => {
        const newObj: TransformedObject = {};

        newObj[`№ ${t("documents_upload.serialNumber")}`] = index + 1;

        for (const [key, value] of Object.entries(item)) {
          if (key === "code") {
            const newKey = t("documents_file_upload.code");
            newObj[newKey] = value;
          }

          if (key === "status") {
            const newKey = t("documents_file_upload.code_status");
            newObj[newKey] = getMarkStatusType(item?.status,t)?.label || "-";
          }

          if (key === "reason") {
            const newKey = t("documents_upload.checkStatus");
            newObj[newKey] = value;
          }
        }

        return newObj;
      });

    return generateAndDownloadExcel(
      results,
      // `orders-check-${props.documentId}`,
      `${t('CheckResult.FileName')}`,
    );
  };

  const renderResultsTable = () => {
    return (
      <>
        {isResultsLoading ? (
          <div className="w-full text-center flex flex-col justify-center items-center gap-[10px]">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <span>{t("documents_upload.pleaseWaitMessage")}!</span>
              <span>{t("documents_upload.dontClosePageMessage")}!</span>
            </div>
            <div className={style.loader}></div>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
              <span>{t("documents_upload.checkResult")}</span>
            </div>
            <div className="rounded-lg overflow-x-auto bg-white">
              <table className="max-w-full w-full leading-normal">
                <thead className="border-b-2 border-gray-300">
                  <tr>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      №{t("documents_upload.serialNumber")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_file_upload.code")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_file_upload.code_status")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_upload.checkStatus")}
                    </th>
                  </tr>
                </thead>
                {resultsTableData?.values?.length === 0 ||
                !resultsTableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_upload.noData")}
                            description={""}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {resultsTableData?.values
                      ?.slice(
                        resultsPagination.pageIndex *
                          resultsPagination.pageSize,
                        (resultsPagination.pageIndex + 1) *
                          resultsPagination.pageSize,
                      )
                      .map((value: any, i: any) => {
                        const index =
                          resultsPagination.pageIndex *
                            resultsPagination.pageSize +
                          i;
                        return (
                          <tr key={`tr-${index}-${value.id}`}>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {index + 1 || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {value?.code || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {getMarkStatusType(value?.status,t)?.label || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <StatusBadge type="error">
                                {value?.reason || "-"}
                              </StatusBadge>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </table>
            </div>
            <Pagination
              totalItems={resultsTableData?.totalItems || 0}
              totalPages={
                resultsTableData?.totalItems
                  ? Math.ceil(
                      resultsTableData?.totalItems / resultsPagination.pageSize,
                    )
                  : 0
              }
              pagination={resultsPagination}
              setPagination={setResultsPagination}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
          <AlertBox
    isOpen={alertOpen} 
    setIsOpen={setAlertOpen} 
    />
      <ModalComponent
        classNameModal={style.modalWrapper}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderTextScan()}
        isOpen={props.isOpen}
        onClose={onModalClose}
        footerContent={
          <button
            className={`text-white font-bold py-2 px-4 rounded ${
              marks?.length === 0 ||
              marks?.split(/\s+/)?.filter((str) => str !== "")?.length === 0
                ? `bg-bg`
                : `bg-green hover:brightness-75`
            }`}
            type="button"
            onClick={() => onLoadResults()}
            disabled={
              marks?.length === 0 ||
              marks?.split(/\s+/)?.filter((str) => str !== "")?.length === 0
            }
          >
            {t("mainButtons4.check")}
          </button>
        }
        cancelText={t("mainButtons.close")}
      />
      <ModalComponent
        classNameModal={style.modalWrapper2}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderResultsTable()}
        isOpen={props.isOpen && resultsModalOpen}
        footerContent={
          isResultsLoading ? (
            <></>
          ) : (
            <button
              className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => generateExcelValues(resultsTableData?.values)}
            >
              {t("documents_upload.donwloadResult")}
            </button>
          )
        }
        cancelText={t("mainButtons.close")}
        onClose={() => {
          onResultsModalClose();
          // onModalClose();
        }}
      />
      <ModalComponent
        isOpen={errorModal}
        onClose={() => setErrorModal(null)}
        headerContent={
          <h1 className="h__modal">
            {t("documents_file_upload.error_notification")}
          </h1>
        }
        content={
          <div className="error-list">
            {/* <h2>{t("documents_file_upload.errors_list")}:</h2> */}
            <p>{errorModal || "-"}</p>
          </div>
        }
        customContent={
          <ModalButton
            type="button"
            onClick={() => {
              setErrorModal(null);
            }}
          >
            {t("mainButtons.accept")}
          </ModalButton>
        }
      />
    </>
  );
};

export default MarkScan;
