import { PaginationState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { addMarksByOrders } from "../../../common/api/nktApi";
import StatusBadge from "../../Ui/StatusBadge/StatusBadge";
import Pagination from "../Table/Pagination";
import AlertBox from "../../Ui/AlertBox/AlertBox";
import ModalComponent from "../../Ui/ModalComponent/ModalWindow";
import { generateAndDownloadExcel } from "../../hooks/generateFiles";
import style from './ModalResult.module.css';
import { useTranslation } from "react-i18next";
import { EmptyListPlaceholderContent } from "../../Ui/EmptyPageBlock";
import { useAppSelector } from "../../hook";
import DocumentsService from "../../../common/api/documentsApi";
import { ITable } from "../Table/types";
import { getMarkStatusType } from "../../documentUtils/dictionary";


interface ModalResultProps {
  documentId: string | undefined;
  toggleOnLoad: boolean;
  setToggleOnLoad: Dispatch<SetStateAction<boolean>>;
  setErrorModal?: any;
  resModal?: any;
  closeModal?: any;
}

export const ModalResult: React.FC<ModalResultProps> = (props) => {
  const [resultsTableData, setResultsTableData] = useState<ITable>();
  const [alertOpen, setAlertOpen] = useState(null);
  const { t, i18n } = useTranslation();
  const pageSize = useAppSelector((state) => state.userSettings.pageSize);
  const [resultsPagination, setResultsPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize,
  });

  const generateExcelValues = (data: any) => {
    type TransformedObject = {
      [key: string]: any;
    };

    const results =
      data &&
      data?.map((item: any, index: number) => {
        const newObj: TransformedObject = {};

        newObj[`№ ${t("documents_upload.serialNumber")}`] = index + 1;

        for (const [key, value] of Object.entries(item)) {
          if (key === "number") {
            const newKey = t("documents_upload.orderNumber");
            newObj[newKey] = value;
          }

          if (key === "marksWithErrorsAmount") {
            const newKey = t("documents_upload.codesAmount");
            newObj[newKey] = value;
          }

          if (key === "checkStatus") {
            const newKey = t("documents_upload.checkStatus");
            newObj[newKey] = value;
          }
        }

        return newObj;
      });

    return generateAndDownloadExcel(
      results,
      // `orders-check-${props.documentId}`,
      `${t('CheckResult.FileName')}`,
    );
  };

  useEffect(() => {
    if (props.resModal) {
      onLoadResults();
    }
  }, [props.resModal])

  async function onLoadResults() {

    if (props.documentId) {
      try {

        const response = await DocumentsService.addMarksResult(props.documentId);
        if (response?.problemMarks?.length >= 0) {
          setResultsTableData({
            values: response.problemMarks,
            currentPage: 0,
            itemsPerPage: response?.total,
            totalItems: response?.total,
            totalPages: 1,
          });
        }
        if (response?.ordersWithErrors?.length >= 0) {
          setResultsTableData({
            values: response.ordersWithErrors,
            currentPage: 0,
            itemsPerPage: response?.totalOrdersWithErrors,
            totalItems: response?.totalOrdersWithErrors,
            totalPages: 1,
          });
        }
      } catch (error: any) {
        if (props.setErrorModal) {
          props.setErrorModal({
            singleError: true,
            errorMessage: error?.response?.data?.message
              ? error?.response?.data?.message
              :
              t("documentNotification.unknown_error")
          });
        }
      } finally {
        props.setToggleOnLoad(!props.toggleOnLoad);
      }
    }
  }

  const renderResultsTable = () => {
    return (
      <>
        <div className="w-full">
          <div className="flex flex-row text-[20px] font-bold gap-[6px] text-gray-700 ">
            <span>{t("documents_upload.checkResult")}</span>
          </div>
          <div>
            {
              (resultsTableData?.values && resultsTableData?.values?.length > 0)
                ? <span className="font-bold text-gray-700 text-[14px]">Всего:{resultsTableData?.values?.length}</span>
                :
                null}
          </div>
          <div className="rounded-lg  overflow-x-auto bg-white">
            <table className="max-w-full w-full leading-normal">
              {resultsTableData?.values && resultsTableData.values[0]?.reason ? (
                <>
                  <thead className="border-b-2 border-gray-300">
                  <tr>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      №{t("documents_upload.serialNumber")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_file_upload.code")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_file_upload.code_status")}
                    </th>
                    <th
                      className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                      scope="col"
                    >
                      {t("documents_upload.checkStatus")}
                    </th>
                  </tr>
                </thead>
                {resultsTableData?.values?.length === 0 ||
                !resultsTableData?.values ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center py-5">
                        <div>
                          <EmptyListPlaceholderContent
                            title={t("documents_upload.noData")}
                            description={''}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="font-normal text-center">
                    {resultsTableData?.values
                      .slice(
                        resultsPagination.pageIndex *
                          resultsPagination.pageSize,
                        (resultsPagination.pageIndex + 1) *
                          resultsPagination.pageSize,
                      )
                      .map((value: any, i: any) => {
                        const index =
                          resultsPagination.pageIndex *
                            resultsPagination.pageSize +
                          i;
                        return (
                          <tr key={`tr-${index}-${value.id}`}>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {index + 1 || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {value?.code || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              {getMarkStatusType(value?.status,t)?.label || "-"}
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                              <StatusBadge type="error">
                                {value?.reason || "-"}
                              </StatusBadge>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
                </>
              ) : (
                <>
                  <thead className="border-b-2 border-gray-300">
                    <tr>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        №{t("documents_upload.serialNumber")}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t("documents_upload.orderNumber")}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t("documents_upload.codesAmount")}
                      </th>
                      <th
                        className="px-5 py-3 text-center text-gray-600 font-semibold bg-white border-b border-gray-200"
                        scope="col"
                      >
                        {t("documents_upload.checkStatus")}
                      </th>
                    </tr>
                  </thead>
                  {resultsTableData?.values?.length === 0 || !resultsTableData?.values ? (
                    <tbody>
                      <tr>
                        <td colSpan={8} className="text-center py-5">
                          <EmptyListPlaceholderContent
                            title={t("documents_upload.noData")}
                            description={''}
                          />
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="font-normal text-center">
                      {resultsTableData?.values
                        .slice(
                          resultsPagination?.pageIndex * resultsPagination?.pageSize,
                          (resultsPagination?.pageIndex + 1) * resultsPagination?.pageSize,
                        )
                        .map((value: any, i: any) => {
                          const index =
                            resultsPagination?.pageIndex * resultsPagination?.pageSize + i;
                          return (
                            <tr key={`tr-${index}-${value?.id}`}>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                {index + 1 || "-"}
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                {value?.number || "-"}
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                {value?.marksWithErrorsAmount || "-"}
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-gray-600">
                                <StatusBadge type="error">
                                  {value?.checkStatus || "-"}
                                </StatusBadge>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </>
              )}
            </table>
          </div>
          <Pagination
            totalItems={resultsTableData?.totalItems || 0}
            totalPages={
              resultsTableData?.totalItems
                ? Math.ceil(
                  resultsTableData?.totalItems /
                  resultsPagination?.pageSize,
                )
                : 0
            }
            pagination={resultsPagination}
            setPagination={setResultsPagination}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <AlertBox
        isOpen={alertOpen}
        setIsOpen={setAlertOpen}
      />
      <ModalComponent
        classNameModal={style.modalWrapper2}
        classNameContent="max-w-[1585px] w-full m-t-[20px]"
        content={renderResultsTable()}
        isOpen={props.resModal}
        footerContent={
          <button
            className="bg-green hover:brightness-75 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={() => generateExcelValues(resultsTableData?.values)}
          >
            {t("documents_upload.donwloadResult")}
          </button>
        }
        cancelText={t("mainButtons.close")}
        onClose={() => {
          if (props.closeModal) {
            props.closeModal();
          }
        }}
      />
    </>
  )

}
